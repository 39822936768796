import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import {ReactElement, useEffect, useState} from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  Button,
  Snackbar,
  Alert,
} from '@mui/material';
import Dashboard from '../../../components/Dashboard';
import {ALERT_HIDE_DURATION} from '../../../constant';
import NoPermissionAlert from '../../../components/Alert/NoPermission';
import {getWebhookList} from '../../../api/webhook';
import {GetWebhookListDto, WebhookDto} from '../../../api/dto/webhook';
import dayjs from 'dayjs';
import WebhookDetailsModal from '../../../components/Webhook/WebhookDetailModal';

function PlatformWebhook(): ReactElement {
  const {t} = useTranslation();
  const [webhooks, setWebhooks] = useState<WebhookDto[]>([]);
  const [selectedWebhook, setSelectedWebhook] = useState<WebhookDto | null>(
    null,
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const obj: GetWebhookListDto = {
      limit: 10,
    };
    getWebhookList(obj)
      ?.then(response => {
        setWebhooks(response?.data?.items);
      })
      .catch(err => {
        if (err) {
          setError(JSON.stringify(err));
          setOpen(true);
        } else {
          setError(t('errorFetchingData'));
          setOpen(true);
        }
      });
  }, []);

  const handleRowClick = (webhook: WebhookDto) => {
    setSelectedWebhook(webhook);
    setModalOpen(true);
  };

  return (
    <Container
      id='webhook'
      sx={{
        pt: {xs: 4, sm: 12},
        pb: {xs: 8, sm: 16},
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: {xs: 3, sm: 6},
      }}
    >
      <Typography component='h2' variant='h4' color='text.primary'>
        {t('dashboardPlatformWebhookPageTitle')}
      </Typography>

      {error ? (
        <NoPermissionAlert t={t} />
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('methodHeader')}</TableCell>
                <TableCell>{t('statusHeader')}</TableCell>
                <TableCell>{t('endpointHeader')}</TableCell>
                <TableCell>{t('createdAtHeader')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {webhooks?.map((webhook, index) => (
                <TableRow
                  key={index}
                  onClick={() => handleRowClick(webhook)}
                  style={{cursor: 'pointer'}}
                >
                  <TableCell>{webhook.method}</TableCell>
                  <TableCell>{webhook.responseStatus}</TableCell>
                  <TableCell>{webhook.endpoint}</TableCell>
                  <TableCell>
                    {dayjs(webhook.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Snackbar
        open={open}
        autoHideDuration={ALERT_HIDE_DURATION}
        onClose={() => setOpen(false)}
      >
        <Alert severity='error' variant='filled' sx={{width: '100%'}}>
          {error}
        </Alert>
      </Snackbar>

      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <WebhookDetailsModal
          t={t}
          selectedWebhook={selectedWebhook}
          setModalOpen={setModalOpen}
        />
      </Modal>
    </Container>
  );
}

export default function DashboardPlatformWebhook() {
  return <Dashboard element={<PlatformWebhook />} />;
}
