import React, {useEffect} from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import {Divider, ListItem, List, Collapse} from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CardTravelTwoToneIcon from '@mui/icons-material/CardTravelTwoTone';
import PeopleIcon from '@mui/icons-material/People';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EngineeringTwoToneIcon from '@mui/icons-material/EngineeringTwoTone';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import AdminPanelSettingsTwoToneIcon from '@mui/icons-material/AdminPanelSettingsTwoTone';
import InfoIcon from '@mui/icons-material/Info';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import NotificationsIcon from '@mui/icons-material/Notifications';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MessageIcon from '@mui/icons-material/Message';
import WebhookIcon from '@mui/icons-material/Webhook';
import {TFunction} from 'i18next';
import {ModuleDto} from '../api/dto/module';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import VoiceChatIcon from '@mui/icons-material/VoiceChat';
import {useState} from 'react';
import {ExpandLess, ExpandMore} from '@mui/icons-material';
import {Link, useLocation} from 'react-router-dom';
import SendIcon from '@mui/icons-material/Send';

// Icon mapping
const iconMap: Record<string, {name: string; icon: React.ReactElement}> = {
  Dashboard: {
    name: 'dashboardMenuDashboard',
    icon: <DashboardIcon />,
  },
  Platform: {
    name: 'dashboardMenuPlatform',
    icon: <CardTravelTwoToneIcon />,
  },
  User: {
    name: 'dashboardMenuUser',
    icon: <PeopleIcon />,
  },
  Role: {
    name: 'dashboardMenuRole',
    icon: <EngineeringTwoToneIcon />,
  },
  PlatformPermission: {
    name: 'dashboardMenuPermission',
    icon: <AdminPanelSettingsTwoToneIcon />,
  },
  Plan: {
    name: 'dashboardMenuPlan',
    icon: <FeaturedPlayListIcon />,
  },
  Verification: {
    name: 'dashboardMenuVerification',
    icon: <VerifiedUserIcon />,
  },
  UserNotification: {
    name: 'dashboardMenuUserNotification',
    icon: <NotificationsIcon />,
  },
  PlatformInfo: {
    name: 'dashboardPlatformInfo',
    icon: <InfoIcon />,
  },
  PlatformUsage: {
    name: 'dashboardPlatformUsagePage',
    icon: <DisplaySettingsIcon />,
  },
  PlatformWebhook: {
    name: 'dashboardPlatformWebhookPage',
    icon: <WebhookIcon />,
  },
  AccountInfo: {
    name: 'dashboardUserMe',
    icon: <AccountCircleIcon />,
  },
  AccountUsage: {
    name: 'dashboardAccount',
    icon: <AssignmentIcon />,
  },
  AccountPermission: {
    name: 'dashboardAccountPermission',
    icon: <PlaylistAddCheckIcon />,
  },
  UserMessage: {
    name: 'dashboardMenuUserMessage',
    icon: <MessageIcon />,
  },
  AIPlatformVoice: {
    name: 'dashboardMenuAIPlatformVoice',
    icon: <RecordVoiceOverIcon />,
  },
  AITTS: {
    name: 'dashboardMenuAITTS',
    icon: <VoiceChatIcon />,
  },
  PlatformEmail: {
    name: 'dashboardPlatformEmail',
    icon: <SendIcon />,
  },
};

const typeMap: Record<string, string> = {
  PlatformInfo: 'dashboardPlatformDetail',
  AccountInfo: 'dashboardAccountDetail',
  AI: 'dashboardAIDetail',
};

type Props = {
  modules: ModuleDto[] | undefined;
  t: TFunction<'translation', undefined>;
  open: boolean;
};

export const DynamicMenu: React.FC<Props> = ({modules, t, open}) => {
  const location = useLocation();
  const [openSections, setOpenSections] = useState<Record<string, boolean>>({});
  useEffect(() => {
    const storedState = localStorage.getItem('menuState');
    if (storedState) {
      setOpenSections(JSON.parse(storedState));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('menuState', JSON.stringify(openSections));
  }, [openSections]);

  const handleToggle = (section: string) => {
    setOpenSections(prev => ({...prev, [section]: !prev[section]}));
  };

  useEffect(() => {
    setOpenSections(prev => ({...prev}));
  }, [location.pathname]);
  if (!modules) return null;

  const categorizedModules = modules?.reduce<Record<string, ModuleDto[]>>(
    (acc, module) => {
      acc[module.type] = acc[module.type] || [];
      acc[module.type].push(module);
      return acc;
    },
    {},
  );

  const sections = [
    {key: 'Main', title: t('dashboardMain')},
    {key: 'PlatformInfo', title: t('dashboardPlatformDetail')},
    {key: 'AI', title: t('dashboardAIDetail')},
    {key: 'AccountInfo', title: t('dashboardAccountDetail')},
  ];

  return (
    <List>
      {sections.map(({key, title}) =>
        categorizedModules[key] ? (
          <div key={key}>
            <ListItemButton onClick={() => handleToggle(key)}>
              {open ? <ListItemText primary={title} /> : <></>}
              {open ? (
                openSections[key] ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )
              ) : (
                <></>
              )}
            </ListItemButton>
            <Collapse
              in={open ? openSections[key] : true}
              timeout='auto'
              unmountOnExit
            >
              <List component='div' disablePadding>
                {categorizedModules[key].map(module => {
                  return (
                    <ListItemButton
                      sx={{
                        pl: 4,
                        backgroundColor:
                          location.pathname === module.link
                            ? '#FFE5B4'
                            : 'transparent',
                        '&:hover': {
                          backgroundColor: '#FFF2D4', // 更淡的米黃色
                        },
                      }}
                      component={Link}
                      to={module.link}
                    >
                      <ListItemIcon>{iconMap[module.name]?.icon}</ListItemIcon>
                      <ListItemText primary={t(iconMap[module.name]?.name)} />
                    </ListItemButton>
                  );
                })}
              </List>
            </Collapse>
            <Divider sx={{my: 1}} />
          </div>
        ) : null,
      )}
    </List>
  );
};

export const DynamicMobileMenu: React.FC<{
  modules: ModuleDto[] | undefined;
  t: (key: string) => string;
}> = ({modules, t}) => {
  if (!modules || modules.length === 0) {
    return <></>;
  }

  const menuItems = modules.reduce<
    {
      section: string;
      items: {text: string; icon: React.ReactElement; onClick: () => void}[];
    }[]
  >((acc, module) => {
    if (module.name === '*') return acc;

    const iconData = iconMap[module.name] || {
      name: module.name,
      icon: <HelpOutlineIcon />,
    };

    let section = acc.find(s => s.section === module.type);
    if (!section) {
      section = {section: module.type, items: []};
      acc.push(section);
    }

    section.items.push({
      text: iconData.name,
      icon: iconData.icon,
      onClick: () => (window.location.href = module.link),
    });

    return acc;
  }, []);

  return (
    <>
      {menuItems.map((item, index) => (
        <React.Fragment key={index}>
          {item.section !== 'Main' ? (
            <ListSubheader component='div' inset>
              {t(typeMap[item.section])}
            </ListSubheader>
          ) : (
            <></>
          )}

          {item.items.map((subItem, subIndex) => (
            <ListItem
              key={`${index}-${subIndex}`}
              sx={{
                flexDirection: 'column',
                textAlign: 'center',
              }}
              onClick={subItem.onClick}
            >
              <ListItemIcon
                sx={{
                  width: 70,
                  height: 70,
                  borderRadius: '50%',
                  backgroundColor: '#f0f0f0',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mb: 1,
                }}
              >
                {subItem.icon}
              </ListItemIcon>
              <ListItemText primary={t(subItem.text)} />
            </ListItem>
          ))}
        </React.Fragment>
      ))}
    </>
  );
};
