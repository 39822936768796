import {AxiosResponse} from 'axios';
import {GetPlatformListDto, PostPlatformDto} from './dto/platform';
import callApi from './index';
import {GetPlatformCreditDto, PlatformCreditDto} from './dto/platformCredit';

export const createPlatform = (data: PostPlatformDto) => {
  return callApi('post', `/platform`, data);
};

export const getPlatformCreditList = (
  data: GetPlatformListDto,
): Promise<AxiosResponse<{items: PlatformCreditDto[]}, any>> | undefined => {
  return callApi('get', `/platform/credit/list`, data);
};

export const getPlatformCredit = (
  data: GetPlatformCreditDto,
): Promise<AxiosResponse<{data: PlatformCreditDto}, any>> | undefined => {
  return callApi('get', `/platform/credit`, data);
};
