import callApi from './index';
import {
  GetPlatformEmailListDto,
  PatchPlatformEmailDto,
  PostPlatformEmailDto,
} from './dto/platformEmail';

export const createPlatformEmail = (data: PostPlatformEmailDto) => {
  return callApi('post', `/platform/email`, data);
};

export const getPlatformEmailList = (data: GetPlatformEmailListDto) => {
  const options = {orderBy: 'createdAt', sort: 'DESC', ...data};
  return callApi('get', `/platform/email/list`, options);
};

export const updatePlatformEmail = (
  id: string,
  data: PatchPlatformEmailDto,
) => {
  return callApi('patch', `/platform/email/${id}`, data);
};

export const deletePlatformEmail = (id: string) => {
  return callApi('delete', `/platform/email/${id}`);
};
