import React, {createContext, useContext, useEffect, useState} from 'react';
import {getModuleList} from '../api/module';
import {ModuleDto} from '../api/dto/module';

interface ModuleContextType {
  modules?: ModuleDto[];
  setModules: (modules?: ModuleDto[]) => void;
}

const ModuleContext = createContext<ModuleContextType | undefined>(undefined);

export const ModuleProvider = ({children}: {children: React.ReactNode}) => {
  const platformId = localStorage.getItem('platformId');
  const [modules, setModules] = useState<ModuleDto[] | undefined>(() => {
    const cachedModule = localStorage.getItem('modules');
    return cachedModule ? JSON.parse(cachedModule) : undefined;
  });

  useEffect(() => {
    if (!modules && platformId) {
      const fetchModules = async () => {
        try {
          const response = await getModuleList({limit: 100});
          if (response?.data?.items) {
            setModules(response.data.items);
            localStorage.setItem(
              'modules',
              JSON.stringify(response.data.items),
            );
          }
        } catch (error) {
          console.error('Error fetching modules:', error);
        }
      };
      fetchModules();
    }
  }, [platformId, modules]);

  return (
    <ModuleContext.Provider value={{modules, setModules}}>
      {children}
    </ModuleContext.Provider>
  );
};

export const useModule = () => {
  const context = useContext(ModuleContext);
  if (!context) {
    throw new Error('useModule must be used within a ModuleProvider');
  }
  return context;
};
