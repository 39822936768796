import {AxiosResponse} from 'axios';
import {
  GetPlatformCreditRecordListDto,
  PatchPlatformCreditRecordDto,
  PostPlatformCreditRecordDto,
  PlatformCreditRecordDto,
} from './dto/platformCreditRecord';
import callApi from './index';

export const createPlatformCreditRecord = (
  data: PostPlatformCreditRecordDto,
): Promise<AxiosResponse<{data: PlatformCreditRecordDto}, any>> | undefined => {
  return callApi('post', `/platform/credit/record`, data);
};

export const getPlatformCreditRecordList = (
  data: GetPlatformCreditRecordListDto,
):
  | Promise<AxiosResponse<{items: PlatformCreditRecordDto[]}, any>>
  | undefined => {
  const options = {orderBy: 'createdAt', sort: 'DESC', ...data};
  return callApi('get', `/platform/credit/record/list`, options);
};

export const updatePlatformCreditRecord = (
  id: string,
  data: PatchPlatformCreditRecordDto,
) => {
  return callApi('patch', `/platform/credit/record/${id}`, data);
};

export const deletePlatformCreditRecord = (id: string) => {
  return callApi('delete', `/platform/credit/record/${id}`);
};
