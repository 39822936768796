import {Autocomplete, TextField, Chip, Box} from '@mui/material';
import {useEffect, useState} from 'react';

const EmailMultiSelect = ({t, cell, column, row}: any) => {
  const mailAddressList = Array.isArray(cell?.getValue())
    ? cell
        .getValue()
        .map((d: {mailAddress: string}) => d.mailAddress)
        .filter((email: any) => email) // 过滤掉空字符串或无效值
    : [];

  const [emails, setEmails] = useState<string[]>(mailAddressList || []);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (row) {
      row._valuesCache[column.id] = emails.map(email => ({
        mailAddress: email,
      }));
    }
  }, [emails, row, column?.id]);

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const handerValues = (values: string[]) => {
    const invalidEmails = values.filter(email => !emailRegex.test(email));

    if (invalidEmails.length > 0) {
      setErrorMessage(`${t('validateEmail')} ${invalidEmails.join(', ')}`);
      return;
    } else {
      setErrorMessage(null);
    }

    setEmails(values);

    if (row) {
      row._valuesCache[column.id] = values.map(email => ({
        mailAddress: email,
      }));
    }
  };

  return (
    <Autocomplete
      multiple
      freeSolo
      options={[]}
      value={emails}
      disabled={cell.row.index >= 0}
      onChange={(_, newValue) => handerValues(newValue)}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            label={option}
            {...getTagProps({index})}
            sx={{
              backgroundColor: '#e3f2fd',
              color: '#0277bd',
              fontSize: '14px',
              borderRadius: '8px',
            }}
          />
        ))
      }
      renderInput={params => (
        <TextField
          {...params}
          variant='outlined'
          placeholder='Add email'
          error={
            column?.columnDef?.muiEditTextFieldProps?.error || !!errorMessage
          }
          helperText={
            column?.columnDef?.muiEditTextFieldProps?.helperText || errorMessage
          }
          required={
            cell?.column?.columnDef?.muiEditTextFieldProps?.required || false
          }
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '8px',
            },
          }}
        />
      )}
    />
  );
};

export default EmailMultiSelect;
