import {useState, useRef} from 'react';
import Tooltip from '@mui/material/Tooltip';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';
import IconButton from '@mui/material/IconButton';
import {Alert, AlertColor, Snackbar, SnackbarOrigin} from '@mui/material';
import {ALERT_HIDE_DURATION} from '../../constant';

interface State extends SnackbarOrigin {
  open: boolean;
  type: AlertColor;
  message: string;
}
function AudioUploader({
  t,
  id,
  handleUpload,
}: {
  t: any;
  id: string;
  handleUpload: (id: string, file: File) => void;
}) {
  const [recording, setRecording] = useState(false);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const streamRef = useRef<MediaStream | null>(null); // 儲存錄音 stream
  const recordingIdRef = useRef<string | null>(null); // 用來追蹤已開始錄音的 id
  const [state, setState] = useState<State>({
    open: false,
    type: 'success',
    message: '',
    vertical: 'bottom',
    horizontal: 'right',
  });

  const {open} = state;

  const handleClick = (type: AlertColor, message: string) => {
    setState({...state, type, message, open: true});
  };
  const handleClose = () => {
    setState(prev => ({...prev, open: false}));
  };

  const startRecording = async () => {
    if (recordingIdRef.current === id) {
      return;
    }

    try {
      const obj: any = {
        name: 'microphone',
      };
      const permissionStatus = await navigator.permissions.query(obj);

      if (permissionStatus.state === 'denied') {
        handleClick(
          'error',
          t('dashboardTableAIPlatformVoiceNeedMicPermission'),
        );
        return;
      }

      // 如果權限為 'prompt' 或 'granted'
      if (
        permissionStatus.state === 'prompt' ||
        permissionStatus.state === 'granted'
      ) {
        const stream = await navigator.mediaDevices.getUserMedia({audio: true});
        streamRef.current = stream;

        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorderRef.current = mediaRecorder;
        audioChunksRef.current = [];

        mediaRecorder.ondataavailable = event => {
          if (event.data.size > 0) {
            audioChunksRef.current.push(event.data);
          }
        };

        mediaRecorder.onstop = () => {
          const audioBlob = new Blob(audioChunksRef.current, {
            type: 'audio/wav',
          });
          const audioFile = new File([audioBlob], 'recording.wav', {
            type: 'audio/wav',
          });

          handleUpload(id, audioFile);

          // 釋放麥克風資源
          streamRef.current?.getTracks().forEach(track => track.stop());
          streamRef.current = null;
          recordingIdRef.current = null;
          setRecording(false);
        };

        mediaRecorderRef.current.start();
        setRecording(true);
        recordingIdRef.current = id;
      }
    } catch (error) {
      if (error instanceof DOMException && error.name === 'NotAllowedError') {
        handleClick('error', JSON.stringify(error));
      } else {
        handleClick('error', JSON.stringify(error));
      }
    }
  };

  // 停止錄音
  const stopRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== 'inactive'
    ) {
      mediaRecorderRef.current.stop(); // 正確停止錄音

      setRecording(false);
    }
  };

  return (
    <div style={{display: 'flex', gap: '5px'}}>
      {/* 錄音按鈕 */}
      {recording ? (
        <Tooltip title={t('dashboardTableAIPlatformVoiceStopRecording')}>
          <IconButton onClick={stopRecording} color='error'>
            <StopIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title={t('dashboardTableAIPlatformVoiceStartRecording')}>
          <IconButton onClick={startRecording} color='secondary'>
            <MicIcon />
          </IconButton>
        </Tooltip>
      )}
      {/* 檔案上傳 */}
      <Tooltip title={t('dashboardTableAIPlatformVoiceUploadTooltip')}>
        <label>
          <input
            type='file'
            accept='.wav'
            style={{display: 'none'}}
            onChange={e => {
              if (e.target.files?.[0]) {
                handleUpload(id, e.target.files[0]);
              }
            }}
          />
          <IconButton component='span' color='primary'>
            <UploadFileIcon />
          </IconButton>
        </label>
      </Tooltip>
      {/* 顯示通知 Snackbar */}
      <Snackbar
        anchorOrigin={{
          vertical: state.vertical,
          horizontal: state.horizontal,
        }}
        open={open}
        autoHideDuration={ALERT_HIDE_DURATION}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={state.type}
          variant='filled'
          sx={{width: '100%'}}
        >
          {state.message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default AudioUploader;
