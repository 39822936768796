import React, {useState, useEffect} from 'react';
import {Box, Button, Typography} from '@mui/material';
import {useParams, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {getPlanPublic} from '../../../api/plan';
import {PlanDto} from '../../../api/dto/plan';
import {DEFAULT_LANG} from '../../../constant';
import TapPayPayment from '../../../components/Tappay/Form';
import {getUserMe} from '../../../api/user';
import {UserDto} from '../../../api/dto/user';
import AppAppBar from '../../../components/AppAppBar';
import {PricingCard} from '../../../components/Pricing';
import {animated, useSpring} from 'react-spring';

declare global {
  interface Window {
    TPDirect: any;
  }
}

const SubscriptionPage: React.FC = () => {
  const localeI18n = localStorage.getItem('i18n') || DEFAULT_LANG;
  const {t, i18n} = useTranslation();
  const {planId} = useParams();
  const navigate = useNavigate();
  const [plan, setPlan] = useState<PlanDto>();
  const [user, setUser] = useState<UserDto | null>(null);

  const animationProps = useSpring({
    from: {opacity: 0, transform: 'scale(0.9)'}, // 初始狀態
    to: {opacity: 1, transform: 'scale(1)'}, // 最終狀態
    config: {tension: 300, friction: 15, duration: 1000}, // 控制彈性
  });

  useEffect(() => {
    i18n.changeLanguage(localeI18n as string);
  }, []);

  useEffect(() => {
    const fetchPlan = async () => {
      try {
        if (planId) {
          const response = await getPlanPublic(planId);
          if (response?.data?.data) {
            setPlan(response.data.data);
          }
        }
      } catch (error) {
        console.error('Failed to fetch plan info:', error);
      }
    };

    const fetchUser = async () => {
      try {
        const response = await getUserMe();
        if (response?.data?.data) {
          setUser(response.data.data);
        } else {
          setUser(null);
        }
      } catch (error) {
        console.error('Failed to fetch user info:', error);
        setUser(null);
      }
    };

    fetchUser();
    fetchPlan();
  }, [planId]);

  if (!user) {
    return (
      <>
        <AppAppBar />

        <Box
          sx={{
            marginTop: '6rem',
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <Typography variant='h6' sx={{mb: 2}}>
            {t('planPayNeedLoginContent')}
          </Typography>
          <Button
            variant='contained'
            color='primary'
            onClick={() => navigate('/sign-in')}
          >
            {t('planPayNeedLoginGoLogin')}
          </Button>
        </Box>
      </>
    );
  }

  if (!planId) {
    return (
      <>
        <AppAppBar />
        <Box
          sx={{
            marginTop: '6rem',
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        ></Box>
      </>
    );
  }

  return (
    <>
      <AppAppBar />
      <Box
        sx={{
          marginTop: '6rem',
          p: 4,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 4,
            maxWidth: '900px',
            width: '100%',
          }}
        >
          {/* 左側：商品資訊 */}
          {plan && (
            <animated.div style={animationProps}>
              <PricingCard plan={plan} t={t} />
            </animated.div>
          )}

          {/* 右側：付款資訊 */}
          <Box
            sx={{
              flex: 2,
              p: 3,
              border: '1px solid #ddd',
              borderRadius: '8px',
              textAlign: 'center',
              boxShadow: 2,
              minWidth: '400px',
            }}
          >
            <Typography variant='h6' gutterBottom>
              {t('planPayNoticeTitle')}
            </Typography>
            <Box
              sx={{
                textAlign: 'left',
                display: 'inline-block',
                mb: 3,
              }}
            >
              <Typography variant='body2' color='textSecondary'>
                {t('planPayNoticeRule1')}
              </Typography>
              <Typography variant='body2' color='textSecondary'>
                {t('planPayNoticeRule2')}
              </Typography>
              <Typography variant='body2' color='textSecondary'>
                {t('planPayNoticeRule3')}
              </Typography>
            </Box>
            <TapPayPayment
              user={user}
              i18n={localeI18n}
              planId={planId}
              t={t}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SubscriptionPage;
