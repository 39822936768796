import React, {useEffect, useState} from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
  IconButton,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {getPlanPublicList} from '../../api/plan';
import {PlanDto} from '../../api/dto/plan';
import {useNavigate} from 'react-router-dom';

interface CreditRechargeDialogProps {
  t: any;
  open: boolean;
  onClose: () => void;
  layout: 'vertical' | 'horizontal';
}

const CreditRecharge: React.FC<CreditRechargeDialogProps> = ({
  t,
  open,
  onClose,
  layout,
}) => {
  const [planList, setPlanList] = useState<PlanDto[]>([]);
  const localeI18n = localStorage.getItem('i18n');
  const navigate = useNavigate();

  useEffect(() => {
    if (open) {
      getPlanPublicList({limit: 10, type: 'Credit'}).then(res => {
        setPlanList(res.data.items || []);
      });
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      {/* Title Section */}
      <DialogTitle
        sx={{
          bgcolor: '#1976d2',
          color: '#fff',
          textAlign: 'center',
          fontWeight: 'bold',
        }}
      >
        {t('dashboardTableCredit+')}
        <IconButton
          edge='end'
          color='inherit'
          onClick={onClose}
          sx={{position: 'absolute', right: 8, top: 8}}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      {/* Content Section */}
      <DialogContent sx={{p: 3, backgroundColor: '#f4f6f8'}}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: layout === 'vertical' ? 'column' : 'row',
            flexWrap: 'wrap',
            gap: 2,
            justifyContent: 'center',
          }}
        >
          {planList.map(plan => {
            const priceObj = plan.i18nPriceObj?.[localeI18n || ''];

            return (
              <Card
                key={plan.id}
                sx={{
                  width: layout === 'vertical' ? '100%' : 'calc(50% - 16px)',
                  borderRadius: 3,
                  background:
                    'linear-gradient(135deg, #ffffff 30%, #f1f5f9 100%)',
                  boxShadow: 4,
                  p: 2,
                  transition:
                    'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                  '&:hover': {
                    boxShadow: 8,
                    transform: 'scale(1.05)',
                  },
                }}
              >
                <CardContent sx={{textAlign: 'center'}}>
                  <Typography
                    variant='h6'
                    sx={{fontWeight: 'bold', color: '#3f51b5', mb: 1}}
                  >
                    {plan.name}
                  </Typography>

                  <Typography
                    variant='body1'
                    sx={{fontSize: 18, color: '#555', fontWeight: 'bold'}}
                  >
                    {t('dashboardTableCredit+Price')}:
                    <span style={{color: '#1976d2', fontWeight: 'bold'}}>
                      {priceObj.currency} {priceObj.price}
                    </span>
                  </Typography>

                  {priceObj.specialPrice && (
                    <Typography
                      variant='body2'
                      color='error'
                      sx={{mt: 1, fontSize: 16, fontWeight: 'bold'}}
                    >
                      {t('dashboardTableCredit+DiscountPrice')}:
                      <span style={{color: '#d32f2f'}}>
                        {priceObj.currency} {priceObj.specialPrice}
                      </span>
                    </Typography>
                  )}

                  <Typography variant='body2' sx={{mt: 2, fontSize: 16}}>
                    {t('dashboardTableCredit+BuyCanGet')}:{' '}
                    <strong style={{color: '#388e3c'}}>
                      {plan.ruleObj?.platformGetCredit}
                    </strong>
                    <img
                      src='/credit.png'
                      alt='credit icon'
                      style={{
                        width: '20px',
                        height: '20px',
                        objectFit: 'contain',
                        marginLeft: '5px',
                        verticalAlign: 'middle',
                      }}
                    />
                  </Typography>

                  <Button
                    variant='contained'
                    color='primary'
                    sx={{
                      mt: 2,
                      borderRadius: 3,
                      textTransform: 'none',
                      fontWeight: 'bold',
                      px: 4,
                      py: 1,
                      fontSize: 16,
                      transition: 'background 0.3s ease-in-out',
                      '&:hover': {backgroundColor: '#1565c0'},
                    }}
                    onClick={() => navigate(`/order/pay/${plan.id}`)}
                  >
                    {t('dashboardTableCredit+Enter')}
                  </Button>
                </CardContent>
              </Card>
            );
          })}
        </Box>
      </DialogContent>

      {/* Footer */}
      <DialogActions sx={{justifyContent: 'center', pb: 2}}>
        <Button
          onClick={onClose}
          color='primary'
          variant='outlined'
          sx={{fontWeight: 'bold', px: 3}}
        >
          {t('dashboardTableCredit+Cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreditRecharge;
