import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import SignIn from './page/SignIn';
import NotFound from './page/NotFound';
import './i18n';
import SignUp from './page/Signup';
import ProductPolicy from './page/ProductPolicy';
import DashboardUser from './page/dashboard/User';
import DashboardPlatform from './page/dashboard/Platform';
import DashboardRole from './page/dashboard/Role';
import DashboardPermission from './page/dashboard/Permission';
import DashboardIndex from './page/dashboard/AccountDashboard';
import DashboardUserAccount from './page/dashboard/account/UserAccount';
import DashboardPlan from './page/dashboard/Plan';
import DashboardUserMe from './page/dashboard/account/UserMe';
import DashboardPlatformUsage from './page/dashboard/platform/Usage';
import DashboardPlatformInfo from './page/dashboard/platform/Info';
import SubscriptionPage from './page/order/pay/[planId]';
import APIDocumentationPage from './page/ApiDocument';
import UserStepsPage from './page/user/UserStep';
import UserVerificationEmailPage from './page/user/UserVerifyEmail';
import ResetPasswordPage from './page/user/ResetPassword';
import ForgotPasswordPage from './page/ForgetPassword';
import ErrorPage from './page/Error';
import AwardsPage from './page/AboutUs';
import DashboardVerification from './page/dashboard/Verification';
import DashboardUserNotification from './page/dashboard/UserNotification';
import DashboardPlatformWebhook from './page/dashboard/platform/Webhook';
import DashboardUserMessage from './page/dashboard/UserMessage';
import DashboardUserPermission from './page/dashboard/account/UserPermission';
import DashboardAIPlatformVoice from './page/dashboard/ai/platformVoice';
import DashboardAITTS from './page/dashboard/ai/tts';
import DashboardPlatformEmail from './page/dashboard/PlatformEmail';

if (!localStorage.getItem('i18n')) {
  localStorage.setItem('i18n', 'zh-TW');
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <BrowserRouter>
    <Routes>
      <Route path='/' element={<App />}></Route>
      <Route path='/error' element={<ErrorPage />}></Route>
      <Route path='/sign-in' element={<SignIn />} />
      <Route path='/sign-up' element={<SignUp />} />
      <Route path='/forget-password' element={<ForgotPasswordPage />} />
      <Route path='/policy' element={<ProductPolicy />} />
      <Route path='/about-us' element={<AwardsPage />} />
      <Route path='/api-document' element={<APIDocumentationPage />} />
      <Route
        path='/user/email/verification'
        element={<UserVerificationEmailPage />}
      />
      <Route path='/user/password/reset' element={<ResetPasswordPage />} />
      <Route path='/user/step' element={<UserStepsPage />} />
      <Route path='/dashboard' element={<DashboardIndex />} />
      <Route path='/dashboard/platforms' element={<DashboardPlatform />} />
      <Route
        path='/dashboard/platformEmails'
        element={<DashboardPlatformEmail />}
      />
      <Route path='/dashboard/users' element={<DashboardUser />} />
      <Route path='/dashboard/roles' element={<DashboardRole />} />
      <Route path='/dashboard/plans' element={<DashboardPlan />} />
      <Route path='/dashboard/permissions' element={<DashboardPermission />} />
      <Route
        path='/dashboard/userMessages'
        element={<DashboardUserMessage />}
      />
      <Route
        path='/dashboard/verifications'
        element={<DashboardVerification />}
      />
      <Route
        path='/dashboard/userNotifications'
        element={<DashboardUserNotification />}
      />
      <Route path='/dashboard/user/usages' element={<DashboardUserAccount />} />
      <Route path='/dashboard/user/me' element={<DashboardUserMe />} />
      <Route
        path='/dashboard/user/permissions'
        element={<DashboardUserPermission />}
      />
      <Route
        path='/dashboard/platform/info'
        element={<DashboardPlatformInfo />}
      />
      <Route
        path='/dashboard/platform/usages'
        element={<DashboardPlatformUsage />}
      />
      <Route
        path='/dashboard/platform/webhook'
        element={<DashboardPlatformWebhook />}
      />
      <Route path='/dashboard/ai/tts' element={<DashboardAITTS />} />
      <Route
        path='/dashboard/ai/platformVoice'
        element={<DashboardAIPlatformVoice />}
      />
      {/* <Route path='/dashboard/choosePlatform' element={<ChoosePlatform />} /> */}
      <Route path='/order/pay/:planId' element={<SubscriptionPage />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
