import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import Dashboard from '../../components/Dashboard';
import React, {ReactElement, useMemo, useState} from 'react';
import TWithProviders, {
  MRT_ColumnDef_Add_Show,
  validateRequired,
} from '../../components/MaterialTable';
import {
  createRole,
  deleteRole,
  getRoleList,
  updateRole,
  updateRolePermissions,
} from '../../api/role';
import dayjs from 'dayjs';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import utc from 'dayjs/plugin/utc';
import {
  Alert,
  AlertColor,
  Chip,
  Collapse,
  IconButton,
  Snackbar,
  SnackbarOrigin,
  Tooltip,
} from '@mui/material';
import {ALERT_HIDE_DURATION} from '../../constant';
import {CreatedAtColumn} from '../../components/Date/CreatedAtColumn';
import EditTablePermissionColumn from '../../components/EditTable/EditTablePermissionColumn';
import {
  PatchRoleDto,
  PatchRolePermissionDto,
  RoleDto,
} from '../../api/dto/role';
import {usePlatformUsage} from '../../context/platformUsage';

dayjs.extend(utc);

function validate(data: any) {
  return {
    name: !validateRequired(data.name) ? 'Name is Required' : '',
  };
}

interface State extends SnackbarOrigin {
  open: boolean;
  type: AlertColor;
  message: string;
}
function RoleContent(): ReactElement {
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const {t} = useTranslation();
  const {platformUsage} = usePlatformUsage();

  const [state, setState] = React.useState<State>({
    open: false,
    type: 'success',
    message: '',
    vertical: 'bottom',
    horizontal: 'right',
  });
  const {open} = state;

  const handleClose = () => {
    setState({...state, open: false});
  };

  const columns = useMemo<MRT_ColumnDef_Add_Show<any>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'Id',
        show: false,
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: 'name',
        header: t('nameHeader'),
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.name,
          helperText: validationErrors?.name,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              name: undefined,
            }),
        },
      },
      {
        accessorKey: 'createdAt',
        header: t('createdAtHeader'),
        enableEditing: false,
        Cell: ({cell}) => <CreatedAtColumn cell={cell} />,
      },
      {
        accessorKey: 'rolePlatformPermissions',
        header: t('permissionHeader'),
        enableEditing: false,
        Edit: ({cell, row, column}) => (
          <EditTablePermissionColumn
            t={t}
            cell={cell}
            row={row}
            column={column}
          />
        ),
        Cell: ({cell}) => {
          const permissions = cell.getValue<any>() ?? [];

          const groupedPermissions = permissions.reduce(
            (acc: any, role: RoleDto) => {
              const scope = role?.platformPermission?.scope || 'default';
              if (!acc[scope]) {
                acc[scope] = [];
              }
              acc[scope].push(role?.platformPermission?.action);
              return acc;
            },
            {},
          );

          // 用來控制每個 scope 的展開狀態
          const [expanded, setExpanded] = useState<{[key: string]: boolean}>(
            {},
          );

          return (
            <>
              {Object.keys(groupedPermissions).map(scope => (
                <Box
                  key={scope}
                  sx={{display: 'flex', flexDirection: 'column', gap: '1rem'}}
                >
                  <Typography
                    variant='subtitle1'
                    sx={{
                      fontWeight: 'bold',
                      marginBottom: '8px',
                      color: 'text.primary',
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: '0.8rem',
                      gap: '8px',
                    }}
                  >
                    {scope}
                    <IconButton
                      size='small'
                      onClick={() =>
                        setExpanded(prev => ({...prev, [scope]: !prev[scope]}))
                      }
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                  </Typography>

                  {/* Collapse 控制區域 */}
                  <Collapse in={expanded[scope]}>
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '8px',
                        flexWrap: 'wrap',
                        maxWidth: '100%',
                      }}
                    >
                      {groupedPermissions[scope].map(
                        (action: any, index: number) => (
                          <Chip
                            key={index}
                            label={`${scope}:${action}`}
                            color='primary'
                            size='small'
                            sx={{
                              fontSize: '0.75rem',
                              borderRadius: '12px',
                              backgroundColor: '#03020D',
                              '& .MuiChip-label': {
                                fontWeight: '500',
                              },
                              boxShadow: 1,
                              ':hover': {
                                backgroundColor: '#559BC5',
                              },
                            }}
                          />
                        ),
                      )}
                    </Box>
                  </Collapse>
                </Box>
              ))}
            </>
          );
        },
      },
    ],
    [t, validationErrors],
  );

  return (
    <Container
      id='pricing'
      sx={{
        pt: {xs: 4, sm: 12},
        pb: {xs: 8, sm: 16},
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: {xs: 3, sm: 6},
      }}
    >
      <Box
        sx={{
          width: {sm: '100%', md: '60%'},
          textAlign: {sm: 'left', md: 'center'},
          display: 'flex',
          flexDirection: 'column',
          alignItems: {sm: 'flex-start', md: 'center'},
          gap: 1.5,
          py: 2,
        }}
      >
        <Typography
          component='h2'
          variant='h4'
          color='text.primary'
          fontWeight='bold'
        >
          {t('dashboardMenuRole')}
        </Typography>
        <Typography
          variant='body1'
          color='text.secondary'
          sx={{maxWidth: '80%', opacity: 0.9}}
        >
          {t('dashboardTableRoleDescription')}
        </Typography>
      </Box>
      <Grid
        container
        spacing={3}
        alignItems='center'
        justifyContent='center'
      ></Grid>
      <Tooltip title={t('dashboardTableCreditTooltip')} arrow>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginBottom: '-1rem',
          }}
        >
          <Chip
            label={
              <Box sx={{display: 'flex', alignItems: 'center', gap: '4px'}}>
                <Typography variant='body2' color='primary'>
                  {platformUsage?.roleCount}
                </Typography>
                /
                <Typography variant='body2' color='text.secondary'>
                  {platformUsage?.ruleObj.maxLimitRoleCount}
                </Typography>
              </Box>
            }
            size='small'
            color='default'
            sx={{
              fontSize: '0.75rem',
              height: 24,
              paddingX: 1.2,
              backgroundColor: 'background.paper', // 讓它更融合背景
              border: '1px solid #ccc', // 添加邊框讓它更清楚
            }}
          />
        </Box>
      </Tooltip>
      <TWithProviders
        props={{
          createApi: async (data: PatchRoleDto & PatchRolePermissionDto) => {
            const role = await createRole(data);
            if (role) {
              await updateRolePermissions(role?.data.data.id, {
                permissions: data.rolePlatformPermissions,
              });
            }
            return role;
          },
          updateApi: async (
            id: string,
            data: PatchRoleDto & PatchRolePermissionDto,
          ) => {
            await updateRolePermissions(id, {
              permissions: data.rolePlatformPermissions,
            });

            return updateRole(id, data);
          },
          getListApi: getRoleList,
          deleteApi: deleteRole,
        }}
        columns={columns}
        validate={validate}
        setValidateErrors={setValidationErrors}
      ></TWithProviders>
      <Snackbar
        anchorOrigin={{vertical: state.vertical, horizontal: state.horizontal}}
        open={open}
        autoHideDuration={ALERT_HIDE_DURATION}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={state.type}
          variant='filled'
          sx={{width: '100%'}}
        >
          {state.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default function DashboardRole() {
  return <Dashboard element={<RoleContent />}></Dashboard>;
}
