import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import Dashboard from '../../../components/Dashboard';
import {ReactElement, useEffect, useState} from 'react';
import {getUserMeAccount} from '../../../api/user';
import {HttpStatusCode} from 'axios';
import {Alert, AlertColor, Snackbar, SnackbarOrigin} from '@mui/material';
import {ALERT_HIDE_DURATION} from '../../../constant';

interface State extends SnackbarOrigin {
  open: boolean;
  type: AlertColor;
  message: string;
}

interface IAccountInfo {
  platformCount: number;
  userCount: number;
  roleCount: number;
  permissionCount: number;
  planCount: number;
}

function Card(title: string, count: number) {
  return (
    <Box
      sx={{
        p: 2,
        minWidth: 300,
        border: '1px solid',
        borderColor: 'divider',
        bgcolor: 'background.default',
        borderRadius: 2,
      }}
    >
      <Box sx={{color: 'text.secondary'}}>{title}</Box>
      <Box
        sx={{color: 'text.primary', fontSize: '2.125rem', fontWeight: 'medium'}}
      >
        {count}
      </Box>
    </Box>
  );
}

function UserAccountContent(): ReactElement {
  const {t} = useTranslation();
  const [accountInfo, setAccountInfo] = useState<IAccountInfo>();
  const [state, setState] = useState<State>({
    open: false,
    type: 'success',
    message: '',
    vertical: 'bottom',
    horizontal: 'right',
  });
  const {open} = state;

  const handleClick = (type: AlertColor, message: string) => {
    setState({...state, type, message, open: true});
  };

  const handleClose = () => {
    setState({...state, open: false});
  };

  useEffect(() => {
    getUserMeAccount()?.then(d => {
      if (d.status !== HttpStatusCode.Ok) {
        handleClick('error', JSON.stringify(d.data));
      } else {
        setAccountInfo(d.data.data);
      }
    });
  }, []);

  return (
    <Container
      id='pricing'
      sx={{
        pt: {xs: 4, sm: 12},
        pb: {xs: 8, sm: 16},
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: {xs: 3, sm: 6},
      }}
    >
      <Box
        sx={{
          width: {sm: '100%', md: '60%'},
          textAlign: {sm: 'left', md: 'center'},
        }}
      >
        <Typography component='h2' variant='h4' color='text.primary'>
          {t('dashboardAccount')}
        </Typography>
        <Typography variant='body1' color='text.secondary'></Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          {Card(t('dashboardMenuPlatform'), accountInfo?.platformCount || 0)}
        </Grid>
        <Grid item xs={6}>
          {Card(t('dashboardMenuUser'), accountInfo?.userCount || 0)}
        </Grid>
        <Grid item xs={6}>
          {Card(t('dashboardMenuRole'), accountInfo?.roleCount || 0)}
        </Grid>
        <Grid item xs={6}>
          {Card(
            t('dashboardMenuPermission'),
            accountInfo?.permissionCount || 0,
          )}
        </Grid>
        <Grid item xs={6}>
          {Card(t('dashboardMenuPlan'), accountInfo?.planCount || 0)}
        </Grid>
      </Grid>

      <Grid
        container
        spacing={3}
        alignItems='center'
        justifyContent='center'
      ></Grid>
      <Snackbar
        anchorOrigin={{vertical: state.vertical, horizontal: state.horizontal}}
        open={open}
        autoHideDuration={ALERT_HIDE_DURATION}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={state.type}
          variant='filled'
          sx={{width: '100%'}}
        >
          {state.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default function DashboardUserAccount() {
  return <Dashboard element={UserAccountContent()}></Dashboard>;
}
