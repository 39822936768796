import {AxiosResponse} from 'axios';
import {
  GetPlatformVoiceListDto,
  PatchPlatformVoiceDto,
  PostPlatformVoiceDto,
  PlatformVoiceDto,
  PutPlatformVoiceUploadDto,
} from './dto/platformVoice';
import callApi from './index';

export const createPlatformVoice = (
  data: PostPlatformVoiceDto,
): Promise<AxiosResponse<{data: PlatformVoiceDto}, any>> | undefined => {
  return callApi('post', `/platform/voice`, data);
};

export const getPlatformVoiceList = (
  data: GetPlatformVoiceListDto,
): Promise<AxiosResponse<{items: PlatformVoiceDto[]}, any>> | undefined => {
  const options = {orderBy: 'createdAt', sort: 'DESC', ...data};
  return callApi('get', `/platform/voice/list`, options);
};

export const updatePlatformVoice = (
  id: string,
  data: PatchPlatformVoiceDto,
) => {
  return callApi('patch', `/platform/voice/${id}`, data);
};

export const uploadPlatformVoice = (
  id: string,
  data: PutPlatformVoiceUploadDto,
): Promise<AxiosResponse<any, any>> => {
  return callApi('put', `/platform/voice/${id}/upload`, data, {}, true) as any;
};

export const deletePlatformVoice = (id: string) => {
  return callApi('delete', `/platform/voice/${id}`);
};
