import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import Dashboard from '../../components/Dashboard';
import React, {ReactElement, useMemo, useState} from 'react';
import TWithProviders, {
  MRT_ColumnDef_Add_Show,
  validateRequired,
} from '../../components/MaterialTable';
import {
  createVerification,
  deleteVerification,
  getVerificationList,
} from '../../api/verification';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {Alert, AlertColor, Snackbar, SnackbarOrigin} from '@mui/material';
import {ALERT_HIDE_DURATION} from '../../constant';
import {CreatedAtColumn} from '../../components/Date/CreatedAtColumn';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import {UserInfoCell} from '../../components/User/TabelCell';
import {StatusColumn} from '../../components/EditTable/ViewTableStatusColumn';

dayjs.extend(utc);

function validate(data: any) {
  return {};
}

interface State extends SnackbarOrigin {
  open: boolean;
  type: AlertColor;
  message: string;
}

const ProviderColumn = ({cell, t}: any) => {
  const provider = cell.getValue();

  const getProviderDisplay = (provider: any) => {
    switch (provider) {
      case 'email':
        return (
          <span
            style={{display: 'flex', alignItems: 'center', color: '#1976D2'}}
          >
            <EmailIcon style={{marginRight: 5}} />
            {t('userVerificationEmail')}
          </span>
        );
      case 'phone':
        return (
          <span
            style={{display: 'flex', alignItems: 'center', color: '#4CAF50'}}
          >
            <PhoneIcon style={{marginRight: 5}} />
            {t('userVerificationPhone')}
          </span>
        );
      default:
        return provider;
    }
  };

  return getProviderDisplay(provider);
};

function VerificationContent(): ReactElement {
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const {t} = useTranslation();

  const [state, setState] = React.useState<State>({
    open: false,
    type: 'success',
    message: '',
    vertical: 'bottom',
    horizontal: 'right',
  });
  const {open} = state;

  const handleClose = () => {
    setState({...state, open: false});
  };

  const columns = useMemo<MRT_ColumnDef_Add_Show<any>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'Id',
        enableEditing: false,
        show: false,
        size: 80,
      },
      {
        accessorKey: 'code',
        header: t('codeHeader'),
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.code,
          helperText: validationErrors?.code,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              code: undefined,
            }),
        },
      },
      {
        accessorKey: 'type',
        header: t('typeHeader'),
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.type,
          helperText: validationErrors?.type,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              type: undefined,
            }),
        },
      },
      {
        accessorKey: 'provider',
        header: t('providerHeader'),
        Cell: ({cell}) => <ProviderColumn t={t} cell={cell} />,
        editVariant: 'select',
        editSelectOptions: ['email'],
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.provider,
          helperText: validationErrors?.provider,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              provider: undefined,
            }),
        },
      },
      {
        accessorKey: 'userId',
        header: t('userHeader'),
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.userId,
          helperText: validationErrors?.userId,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              userId: undefined,
            }),
        },
        Cell: ({row}) => <UserInfoCell t={t} user={row.original.user} />,
      },
      {
        accessorKey: 'status',
        header: t('statusHeader'),
        editVariant: 'select',
        editSelectOptions: ['Finish', 'Fail', 'Pending'],
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.status,
          helperText: validationErrors?.status,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              status: undefined,
            }),
        },
        Cell: ({cell}) => <StatusColumn t={t} cell={cell} />,
      },
      {
        accessorKey: 'createdAt',
        header: t('createdAtHeader'),
        enableEditing: false,
        Cell: ({cell}) => <CreatedAtColumn cell={cell} />,
      },
    ],
    [t, validationErrors],
  );

  return (
    <Container
      id='pricing'
      sx={{
        pt: {xs: 4, sm: 12},
        pb: {xs: 8, sm: 16},
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: {xs: 3, sm: 6},
      }}
    >
      <Box
        sx={{
          width: {sm: '100%', md: '60%'},
          textAlign: {sm: 'left', md: 'center'},
          display: 'flex',
          flexDirection: 'column',
          alignItems: {sm: 'flex-start', md: 'center'},
          gap: 1.5,
          py: 2,
        }}
      >
        <Typography
          component='h2'
          variant='h4'
          color='text.primary'
          fontWeight='bold'
        >
          {t('dashboardMenuVerification')}
        </Typography>
        <Typography
          variant='body1'
          color='text.secondary'
          sx={{maxWidth: '80%', opacity: 0.9}}
        >
          {t('dashboardTableVerificationDescription')}
        </Typography>
      </Box>
      <Grid
        container
        spacing={3}
        alignItems='center'
        justifyContent='center'
      ></Grid>
      <TWithProviders
        props={{
          createApi: createVerification,
          getListApi: getVerificationList,
          deleteApi: deleteVerification,
        }}
        columns={columns}
        validate={validate}
        setValidateErrors={setValidationErrors}
      ></TWithProviders>
      <Snackbar
        anchorOrigin={{vertical: state.vertical, horizontal: state.horizontal}}
        open={open}
        autoHideDuration={ALERT_HIDE_DURATION}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={state.type}
          variant='filled'
          sx={{width: '100%'}}
        >
          {state.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default function DashboardVerification() {
  return <Dashboard element={<VerificationContent />}></Dashboard>;
}
