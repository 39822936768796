import {AxiosResponse} from 'axios';
import {GetTTSListDto, PatchTTSDto, PostTTSDto, TTSDto} from './dto/tts';
import callApi from './index';

export const createTTS = (
  data: PostTTSDto,
): Promise<AxiosResponse<{data: TTSDto}, any>> | undefined => {
  return callApi('post', `/tts`, data);
};

export const getTTSList = (
  data: GetTTSListDto,
): Promise<AxiosResponse<{items: TTSDto[]}, any>> | undefined => {
  const options = {orderBy: 'createdAt', sort: 'DESC', ...data};
  return callApi('get', `/tts/list`, options);
};

export const updateTTS = (id: string, data: PatchTTSDto) => {
  return callApi('patch', `/tts/${id}`, data);
};

export const deleteTTS = (id: string) => {
  return callApi('delete', `/tts/${id}`);
};
