import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import Dashboard from '../../components/Dashboard';
import React, {ReactElement, useMemo, useState} from 'react';
import TWithProviders, {
  MRT_ColumnDef_Add_Show,
  validateRequired,
  validateUrl,
} from '../../components/MaterialTable';
import {
  createPlatform,
  deletePlatform,
  getPlatformList,
  updatePlatform,
} from '../../api/platform';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  Alert,
  AlertColor,
  Chip,
  Snackbar,
  SnackbarOrigin,
  Switch,
  Tooltip,
} from '@mui/material';
import {ALERT_HIDE_DURATION} from '../../constant';
import {CreatedAtColumn} from '../../components/Date/CreatedAtColumn';
import {CheckCircle, Person} from '@mui/icons-material';
import EditTableStringArrayColumn from '../../components/EditTable/EditTableStringArrayColumn';
import EditTableBooleanColumn from '../../components/EditTable/EditTableBooleanColumn';
import {usePlatformUsage} from '../../context/platformUsage';
dayjs.extend(utc);

function validate(data: any) {
  return {
    name: !validateRequired(data.name) ? 'Name is Required' : '',
    policyUrl: !validateUrl(data.policyUrl) ? 'PolicyUrl is Error' : '',
    webhookNotifyUrl: !validateUrl(data.webhookNotifyUrl)
      ? 'WebhookNotifyUrl is Error'
      : '',
  };
}

interface State extends SnackbarOrigin {
  open: boolean;
  type: AlertColor;
  message: string;
}
function PlatformContent(): ReactElement {
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const {t} = useTranslation();
  const platformId = localStorage.getItem('platformId');
  const {platformUsage} = usePlatformUsage();

  const [state, setState] = React.useState<State>({
    open: false,
    type: 'success',
    message: '',
    vertical: 'bottom',
    horizontal: 'right',
  });
  const {open} = state;
  const userGroupId = localStorage.getItem('groupId');

  const handleClose = () => {
    setState({...state, open: false});
  };

  const columns = useMemo<MRT_ColumnDef_Add_Show<any>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'Id',
        enableEditing: false,
        size: 60,
      },
      {
        accessorKey: 'name',
        header: t('nameHeader'),
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.name,
          helperText: validationErrors?.name,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              name: undefined,
            }),
        },
        size: 10,
      },
      {
        accessorKey: 'returnUrl',
        header: t('returnUrlHeader'),
        muiEditTextFieldProps: {
          required: true,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              returnUrl: undefined,
            }),
        },
        Edit: ({cell, row, column}) => (
          <EditTableStringArrayColumn
            t={t}
            cell={cell}
            row={row}
            column={column}
            placeHolder=''
          />
        ),
        Cell: ({cell}) => {
          const stringArray = cell.getValue<string[]>();

          return (
            <Box sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
              {stringArray && stringArray.length > 0 ? (
                stringArray.map((str, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      p: 1,
                      border: '1px solid #e0e0e0',
                      borderRadius: 1,
                      backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#ffffff', // 奇偶行背景色
                    }}
                  >
                    <Typography
                      variant='body2'
                      color='text.primary'
                      sx={{
                        fontWeight: 500,
                      }}
                    >
                      {`#${index + 1}`}
                    </Typography>
                    <Typography
                      variant='body2'
                      color='text.secondary'
                      sx={{marginLeft: 2}}
                    >
                      {str}
                    </Typography>
                  </Box>
                ))
              ) : (
                <Typography variant='body2' color='text.secondary'>
                  N/A
                </Typography>
              )}
            </Box>
          );
        },

        size: 30,
      },
      {
        accessorKey: 'cancelUrl',
        header: t('cancelUrlHeader'),
        show: false,
        muiEditTextFieldProps: {
          required: true,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              cancelUrl: undefined,
            }),
        },
        Edit: ({cell, row, column}) => (
          <EditTableStringArrayColumn
            t={t}
            cell={cell}
            row={row}
            column={column}
            placeHolder=''
          />
        ),
        size: 50,
      },
      {
        accessorKey: 'homePageUrl',
        header: t('homePageUrlHeader'),
        show: false,
        muiEditTextFieldProps: {
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              homePageUrl: undefined,
            }),
        },
        size: 50,
      },
      {
        accessorKey: 'isUserEmailVerify',
        header: t('isUserEmailVerifyHeader'),
        show: true,
        editSelectOptions: ['true', 'false'],
        muiEditTextFieldProps: {
          select: true,
          defaultChecked: true,
          defaultValue: true,

          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              isShow: undefined,
            }),
        },
        Edit: ({cell, row, column}) => (
          <EditTableBooleanColumn
            label={t('dashboardTablePlatformIsEmailVerify')}
            t={t}
            cell={cell}
            row={row}
            column={column}
          />
        ),
        Cell: ({cell}) => (
          <Switch
            checked={cell.getValue<boolean>()}
            inputProps={{'aria-label': 'controlled'}}
            color='primary'
            disabled
          />
        ),
      },
      {
        accessorKey: 'policyUrl',
        header: t('policyUrlHeader'),
        show: false,
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.policyUrl,
          helperText: validationErrors?.policyUrl,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              policyUrl: undefined,
            }),
        },
        size: 50,
      },
      {
        header: t('identityHeader'),
        enableEditing: false,
        Cell: ({row}) => {
          const isOwner = row.original.groupId === userGroupId;

          return (
            <Box
              display='flex'
              alignItems='center'
              gap={1}
              color={isOwner ? 'green' : 'gray'}
            >
              {isOwner ? (
                <CheckCircle style={{color: 'green'}} />
              ) : (
                <Person style={{color: 'gray'}} />
              )}
              <Typography variant='body2'>
                {isOwner
                  ? t('dashboardTablePlatformOwner')
                  : t('dashboardTablePlatformParticipant')}
              </Typography>
            </Box>
          );
        },
        size: 50,
      },
      {
        accessorKey: 'webhookNotifyUrl',
        header: t('webhookNotifyUrlHeader'),
        show: false,
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.webhookNotifyUrl,
          helperText: validationErrors?.webhookNotifyUrl,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              webhookNotifyUrl: undefined,
            }),
        },
        size: 50,
      },
      // {
      //   accessorKey: 'isActive',
      //   header: 'IsActive',
      //   editSelectOptions: ['true', 'false'],
      //   muiEditTextFieldProps: {
      //     select: true,
      //     defaultChecked: true,
      //     defaultValue: true,
      //     error: !!validationErrors?.isActive,
      //     helperText: validationErrors?.isActive,
      //     onFocus: () =>
      //       setValidationErrors({
      //         ...validationErrors,
      //         isActive: undefined,
      //       }),
      //   },
      //   Edit: ({cell, row, column}) => (
      //     <EditTableBooleanColumn t={t} cell={cell} row={row} column={column} />
      //   ),
      //   Cell: ({cell}) => (
      //     <Switch
      //       checked={cell.getValue<boolean>()}
      //       inputProps={{'aria-label': 'controlled'}}
      //       color='primary'
      //       disabled
      //     />
      //   ),
      // },
      {
        accessorKey: 'createdAt',
        header: t('createdAtHeader'),
        enableEditing: false,
        Cell: ({cell}) => <CreatedAtColumn cell={cell} />,
      },
    ],
    [t, validationErrors],
  );

  return (
    <Container
      id='pricing'
      sx={{
        pt: {xs: 4, sm: 12},
        pb: {xs: 8, sm: 16},
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: {xs: 3, sm: 6},
      }}
    >
      <Box
        sx={{
          width: {sm: '100%', md: '60%'},
          textAlign: {sm: 'left', md: 'center'},
          display: 'flex',
          flexDirection: 'column',
          alignItems: {sm: 'flex-start', md: 'center'},
          gap: 1.5,
          py: 2,
        }}
      >
        <Typography
          component='h2'
          variant='h4'
          color='text.primary'
          fontWeight='bold'
        >
          {t('dashboardMenuPlatform')}
        </Typography>
        <Typography
          variant='body1'
          color='text.secondary'
          sx={{maxWidth: '80%', opacity: 0.9}}
        >
          {t('dashboardTablePlatformDescription')}
        </Typography>
      </Box>
      <Grid
        container
        spacing={3}
        alignItems='center'
        justifyContent='center'
      ></Grid>
      <Tooltip title={t('dashboardTableCreditTooltip')} arrow>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginBottom: '-1rem',
          }}
        >
          <Chip
            label={
              <Box sx={{display: 'flex', alignItems: 'center', gap: '4px'}}>
                <Typography
                  variant='body2'
                  color={
                    platformUsage &&
                    platformUsage?.platformCount >
                      platformUsage?.ruleObj.maxLimitPlatformCount
                      ? 'error.main'
                      : platformUsage?.platformCount ===
                        platformUsage?.ruleObj.maxLimitPlatformCount
                      ? 'warning.main'
                      : 'primary'
                  }
                >
                  {platformUsage?.platformCount}
                </Typography>
                /
                <Typography variant='body2' color='text.secondary'>
                  {platformUsage?.ruleObj.maxLimitPlatformCount}
                </Typography>
              </Box>
            }
            size='small'
            color='default'
            sx={{
              fontSize: '0.75rem',
              height: 24,
              paddingX: 1.2,
              backgroundColor: 'background.paper', // 讓它更融合背景
              border: '1px solid #ccc', // 添加邊框讓它更清楚
            }}
          />
        </Box>
      </Tooltip>
      <TWithProviders
        props={{
          createApi: createPlatform,
          updateApi: updatePlatform,
          getListApi: getPlatformList,
          deleteApi: deletePlatform,
        }}
        columns={columns}
        onlyEditDeleteByIncludeIds={[platformId ?? '']}
        validate={validate}
        setValidateErrors={setValidationErrors}
      ></TWithProviders>
      <Snackbar
        anchorOrigin={{vertical: state.vertical, horizontal: state.horizontal}}
        open={open}
        autoHideDuration={ALERT_HIDE_DURATION}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={state.type}
          variant='filled'
          sx={{width: '100%'}}
        >
          {state.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default function DashboardPlatform() {
  return <Dashboard element={<PlatformContent />}></Dashboard>;
}
