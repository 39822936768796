import {Box, Typography, Button, Stack, Paper} from '@mui/material';
import dayjs from 'dayjs';
import {WebhookDto} from '../../api/dto/webhook';

interface WebhookDetailsModalProps {
  selectedWebhook: WebhookDto | null;
  setModalOpen: (open: boolean) => void;
  t: (key: string) => string;
}

const WebhookDetailsModal: React.FC<WebhookDetailsModalProps> = ({
  selectedWebhook,
  setModalOpen,
  t,
}) => {
  if (!selectedWebhook) return null;

  const webhookDetails = [
    {label: t('queryHeader'), value: selectedWebhook.query},
    // {label: t('statusHeader'), value: selectedWebhook.status},
    {label: t('retryTimesHeader'), value: selectedWebhook.retryTimes},
    {label: t('endpointHeader'), value: selectedWebhook.endpoint},
    {label: t('methodHeader'), value: selectedWebhook.method},
    {
      label: t('bodyHeader'),
      value: JSON.stringify(selectedWebhook.body, null, 2),
      isJson: true,
    },
    {
      label: t('responseHeader'),
      value: JSON.stringify(selectedWebhook.response, null, 2),
      isJson: true,
    },
    {label: t('statusHeader'), value: selectedWebhook.responseStatus},
    {
      label: t('createdAtHeader'),
      value: dayjs(selectedWebhook.createdAt).format('YYYY-MM-DD HH:mm:ss'),
    },
  ];

  return (
    <Box
      sx={{
        p: 4,
        bgcolor: 'background.paper',
        borderRadius: 2,
        boxShadow: 3,
        maxWidth: 600,
        width: '90%',
        mx: 'auto',
        my: '10%',
        maxHeight: '80vh', // 限制最大高度
        overflowY: 'auto', // 允許滾動
      }}
    >
      <Typography
        variant='h4'
        color='text.primary'
        sx={{
          mb: 3,
          fontWeight: 'bold',
          textAlign: 'center',
        }}
      >
        {t('dashboardPlatformWebhookPageInfoTitle')}
      </Typography>

      <Stack spacing={2}>
        {webhookDetails.map(({label, value, isJson}, index) => (
          <Paper key={index} sx={{p: 2, bgcolor: 'grey.100', borderRadius: 1}}>
            <Typography variant='body2' fontWeight='bold'>
              {label}:
            </Typography>
            <Typography
              variant='body2'
              sx={{
                fontFamily: isJson ? 'monospace' : 'inherit',
                whiteSpace: isJson ? 'pre-wrap' : 'normal',
                wordBreak: 'break-word',
              }}
            >
              {value}
            </Typography>
          </Paper>
        ))}
      </Stack>

      <Button
        onClick={() => setModalOpen(false)}
        sx={{mt: 3, display: 'block', mx: 'auto'}}
      >
        {t('Close')}
      </Button>
    </Box>
  );
};

export default WebhookDetailsModal;
