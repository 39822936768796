import {useEffect, useState} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  List,
  ListItem,
  IconButton,
  Tooltip,
  Avatar,
  Box,
  Paper,
  Divider,
} from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import PersonIcon from '@mui/icons-material/Person';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import {getUserMessageList} from '../../../api/userAIMessage';
import {UserDto} from '../../../api/dto/user';
import NoPermissionAlert from '../../Alert/NoPermission';
import {UserMessageDto} from '../../../api/dto/userMessage';

export const UserMessagesCell = ({user, t}: {user?: UserDto; t: any}) => {
  const [messages, setMessages] = useState<UserMessageDto[]>([]);
  const [noPermission, setNoPermission] = useState<boolean>(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    let isMounted = true;

    const fetchMessages = async () => {
      try {
        if (!open) return;
        const response = await getUserMessageList({userId: user?.id});

        if (isMounted) {
          setNoPermission(false);

          if (Array.isArray(response?.data?.items)) {
            const sortedMessages = response?.data.items.sort(
              (a, b) =>
                new Date(b.createdAt).getTime() -
                new Date(a.createdAt).getTime(),
            );
            setMessages(sortedMessages as any);
          }
        }
      } catch (error) {
        setNoPermission(true);
        console.error('Failed to fetch messages:', error);
      }
    };

    fetchMessages();
    return () => {
      isMounted = false;
    };
  }, [open]);

  return (
    <>
      <Tooltip title={t('dashboardTableUserMessageTooltip')}>
        <IconButton onClick={() => setOpen(true)}>
          <ChatIcon color='primary' />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth='sm'
        sx={{'& .MuiPaper-root': {borderRadius: 3, boxShadow: 4}}}
      >
        <DialogTitle
          sx={{fontSize: '1.25rem', fontWeight: 'bold', textAlign: 'center'}}
        >
          {t('userMessageHeader')}
        </DialogTitle>
        <DialogContent dividers sx={{backgroundColor: '#f7f9fc'}}>
          {noPermission ? (
            <NoPermissionAlert t={t} />
          ) : messages.length > 0 ? (
            <List sx={{p: 0}}>
              {messages.map((msg, index) => (
                <ListItem
                  key={index}
                  sx={{display: 'flex', alignItems: 'flex-start', p: 2}}
                >
                  <Avatar
                    sx={{
                      bgcolor: 'primary.main',
                      mr: 2,
                      width: 40,
                      height: 40,
                    }}
                  >
                    {msg?.user?.username?.charAt(0)?.toUpperCase() || 'U'}
                  </Avatar>

                  <Box sx={{display: 'flex', flexDirection: 'column', flex: 1}}>
                    <Typography
                      variant='caption'
                      color='textSecondary'
                      sx={{mb: 1}}
                    >
                      {t('scene')}: {msg.scene} | {t('type')}: {msg.model}
                    </Typography>

                    <Paper
                      elevation={3}
                      sx={{
                        p: 1.5,
                        bgcolor: '#e3f2fd',
                        borderRadius: '12px 12px 0px 12px',
                        maxWidth: '75%',
                      }}
                    >
                      {msg.messages.map((message, idx) => (
                        <Box
                          key={idx}
                          sx={{display: 'flex', alignItems: 'center', mb: 0.5}}
                        >
                          {message.role === 'user' ? (
                            <PersonIcon sx={{mr: 1, color: 'primary.main'}} />
                          ) : (
                            <SmartToyIcon
                              sx={{mr: 1, color: 'secondary.main'}}
                            />
                          )}
                          <Typography variant='body2'>
                            {message.content}
                          </Typography>
                        </Box>
                      ))}
                      <Typography
                        variant='caption'
                        color='textSecondary'
                        sx={{fontSize: '0.7rem'}}
                      >
                        {new Date(msg.createdAt).toLocaleTimeString()}
                      </Typography>
                    </Paper>
                  </Box>

                  <Paper
                    elevation={3}
                    sx={{
                      p: 1.5,
                      bgcolor: '#fce4ec',
                      borderRadius: '12px 12px 12px 0px',
                      maxWidth: '30%',
                      ml: 2,
                    }}
                  >
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                      <SmartToyIcon sx={{mr: 1, color: 'secondary.main'}} />
                      <Typography variant='body2'>
                        {msg.responseMessage}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        mt: 0.5,
                      }}
                    >
                      <Typography
                        variant='caption'
                        color='textSecondary'
                        sx={{fontSize: '0.7rem'}}
                      >
                        {msg?.updatedAt ? (
                          new Date(msg?.updatedAt).toLocaleTimeString()
                        ) : (
                          <></>
                        )}
                      </Typography>
                    </Box>
                  </Paper>
                </ListItem>
              ))}
              <Divider sx={{my: 2}} />
            </List>
          ) : (
            <Typography align='center' color='textSecondary'>
              {t('dashboardTableUserMessageNoMessage')}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpen(false)}
            variant='contained'
            color='primary'
            sx={{borderRadius: 2}}
          >
            {t('Close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
