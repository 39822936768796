import {AxiosResponse} from 'axios';
import callApi from './index';
import {GetModuleListDto, ModuleDto} from './dto/module';

export const getModuleList = (
  data: GetModuleListDto,
): Promise<AxiosResponse<{items: ModuleDto[]}, any>> | undefined => {
  const options = {orderBy: 'weights', sort: 'DESC', ...data};
  return callApi('get', `/module/list`, options);
};
