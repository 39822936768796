import React, {useState, useEffect} from 'react';
import {
  IconButton,
  Badge,
  Menu,
  MenuItem,
  ListItemText,
  Typography,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {TFunction} from 'i18next';
import {
  GetUserNotificationListDto,
  UserNotificationDto,
} from '../../api/dto/userNotification';
import {
  getUserMeNotificationList,
  patchUserNotification,
} from '../../api/userNotification';
import CloseIcon from '@mui/icons-material/Close';

const NotificationButton = ({t}: {t: TFunction<'translation', undefined>}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [notifications, setNotifications] = useState<
    Array<UserNotificationDto>
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedNotification, setSelectedNotification] =
    useState<UserNotificationDto | null>(null);

  const unreadCount = notifications.filter(n => !n.isRead).length;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationClick = async (notification: UserNotificationDto) => {
    setSelectedNotification(notification);

    if (!notification.isRead) {
      try {
        await patchUserNotification(notification.id, {isRead: true});
        setNotifications(prev =>
          prev.map(n => (n.id === notification.id ? {...n, isRead: true} : n)),
        );
      } catch (error) {
        console.error('Failed to mark notification as read:', error);
      }
    }
  };

  const fetchNotifications = async () => {
    setLoading(true);
    try {
      const data: GetUserNotificationListDto = {
        orderBy: 'createdAt',
        sort: 'DESC',
      };
      const response = await getUserMeNotificationList(data);
      setNotifications(response?.data?.items ?? []);
    } catch (error) {
      console.error('Failed to fetch notifications:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <>
      <IconButton color='inherit' onClick={handleClick}>
        <Badge badgeContent={unreadCount} color='warning'>
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '12px',
            backgroundColor: '#F5F5F5',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
            backdropFilter: 'blur(10px)',
            border: '1px solid #E0E0E0',
            color: '#333',
          },
        }}
      >
        {loading ? (
          <MenuItem
            disabled
            sx={{display: 'flex', justifyContent: 'center', width: '320px'}}
          >
            <CircularProgress size={24} sx={{color: '#007ACC'}} />
          </MenuItem>
        ) : notifications.length > 0 ? (
          <>
            <MenuItem
              disabled
              sx={{
                width: '320px',
                textAlign: 'center',
                fontWeight: 'bold',
                fontFamily: `'Roboto', sans-serif`,
                color: '#333',
                borderBottom: '1px solid #000000',
              }}
            >
              {t('notificationPageTitle')} ({notifications.length})
            </MenuItem>
            {notifications.map((notification, index) => (
              <React.Fragment key={notification.id}>
                <MenuItem
                  onClick={() => handleNotificationClick(notification)}
                  sx={{
                    width: '320px',
                    maxWidth: '100%',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      backgroundColor: '#E3F2FD',
                    },
                  }}
                >
                  <ListItemText
                    primary={notification.title}
                    primaryTypographyProps={{
                      sx: {
                        fontWeight: notification.isRead ? 'normal' : 'bold',
                        color: notification.isRead ? '#757575' : '#007ACC',
                      },
                    }}
                  />
                </MenuItem>
                {index < notifications.length - 1 && (
                  <Divider sx={{backgroundColor: '#E0E0E0'}} />
                )}
              </React.Fragment>
            ))}
          </>
        ) : (
          <MenuItem
            disabled
            sx={{width: '320px', textAlign: 'center', color: '#757575'}}
          >
            <Typography>{t('notificationPageNoNotify')}</Typography>
          </MenuItem>
        )}
      </Menu>
      <Dialog
        open={Boolean(selectedNotification)}
        onClose={() => setSelectedNotification(null)}
        PaperProps={{
          sx: {
            borderRadius: '20px',
            boxShadow: '0 10px 40px rgba(0, 0, 0, 0.1)',
            padding: '24px',
            maxWidth: '80rem',
            minWidth: '20rem',
            backdropFilter: 'blur(10px)',
            backgroundColor: '#FFFFFF',
            border: '1px solid #E0E0E0',
            color: '#333',
          },
        }}
      >
        <DialogTitle
          sx={{fontWeight: 'bold', fontSize: '1.5rem', color: '#007ACC'}}
        >
          {selectedNotification?.title}
          <IconButton onClick={() => setSelectedNotification(null)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography sx={{fontSize: '1rem', color: '#555'}}>
            {selectedNotification?.message}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setSelectedNotification(null)}
            variant='contained'
            sx={{backgroundColor: '#007ACC', color: 'white'}}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NotificationButton;
