import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import Dashboard from '../../components/Dashboard';
import React, {ReactElement, useMemo, useState} from 'react';
import TWithProviders, {
  MRT_ColumnDef_Add_Show,
  validateEmail,
  validateLengthMoreThanEqual,
  validateLoginType,
  validateRequired,
} from '../../components/MaterialTable';
import {
  createUser,
  deleteUser,
  getUserList,
  updateUser,
  updateUserSetRole,
} from '../../api/user';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  Alert,
  AlertColor,
  Chip,
  Snackbar,
  SnackbarOrigin,
  Tooltip,
} from '@mui/material';
import {ALERT_HIDE_DURATION} from '../../constant';
import {CreatedAtColumn} from '../../components/Date/CreatedAtColumn';
import EditTableRoleColumn from '../../components/EditTable/EditTableRoleColumn';
import {PatchUserDto, PostUserDto, PutUserSetRoleDto} from '../../api/dto/user';
import {UserMessagesCell} from '../../components/User/Message/TableCell';
import {usePlatform} from '../../context/platform';
import {MRT_Cell} from 'material-react-table';
import {usePlatformUsage} from '../../context/platformUsage';
dayjs.extend(utc);

function validate(data: any) {
  return {
    username: !validateRequired(data.username) ? 'User Name is Required' : '',
    email: !validateEmail(data.email) ? 'Incorrect Email Format' : '',
    password: !validateLengthMoreThanEqual(data.password, 6)
      ? 'Password must be more than 6'
      : '',
    loginType: !validateLoginType(data.loginType)
      ? 'LoginType Must be `email` or `google`'
      : '',
  };
}

interface State extends SnackbarOrigin {
  open: boolean;
  type: AlertColor;
  message: string;
}
function UserContent(): ReactElement {
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const {t} = useTranslation();
  const {platform} = usePlatform();
  const {platformUsage} = usePlatformUsage();

  const [state, setState] = React.useState<State>({
    open: false,
    type: 'success',
    message: '',
    vertical: 'bottom',
    horizontal: 'right',
  });
  const {open} = state;

  const handleClose = () => {
    setState({...state, open: false});
  };

  const columns = useMemo<MRT_ColumnDef_Add_Show<any>[]>(() => {
    const filteredColumns = [
      {
        accessorKey: 'id',
        header: 'Id',
        enableEditing: false,
        show: false,
        size: 80,
      },
      {
        accessorKey: 'username',
        header: t('usernameHeader'),
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.username,
          helperText: validationErrors?.username,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              username: undefined,
            }),
        },
      },
      {
        accessorKey: 'email',
        header: t('emailHeader'),
        muiEditTextFieldProps: {
          type: 'email',
          required: true,
          error: !!validationErrors?.email,
          helperText: validationErrors?.email,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              email: undefined,
            }),
        },
      },
      {
        accessorKey: 'externalId',
        header: t('externalIdHeader'),
        show: false,
        muiEditTextFieldProps: {
          error: !!validationErrors?.externalId,
          helperText: validationErrors?.externalId,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              externalId: undefined,
            }),
        },
      },
      {
        accessorKey: 'password',
        header: t('passwordHeader'),
        enableSorting: false,
        show: false,
        Cell: () => (
          <Box sx={{display: 'flex', gap: '2ch', alignItems: 'center'}}>
            ******
          </Box>
        ),
        muiEditTextFieldProps: {
          type: 'password',
          required: true,
          error: !!validationErrors?.password,
          helperText: validationErrors?.password,
        },
      },
      {
        accessorKey: 'loginType',
        header: t('loginTypeHeader'),
        editVariant: 'select' as 'select',
        editSelectOptions: ['email'],
        show: false,
        muiEditTextFieldProps: {
          type: 'loginType',
          required: false,
          error: !!validationErrors?.loginType,
          helperText: validationErrors?.loginType,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              loginType: undefined,
            }),
        },
      },
      {
        accessorKey: 'createdAt',
        header: t('createdAtHeader'),
        enableEditing: false,
        Cell: ({cell}: any) => <CreatedAtColumn cell={cell} />,
      },
      {
        accessorKey: 'userMessages',
        header: t('userMessageHeader'),
        enableEditing: true,
        muiEditTextFieldProps: {
          sx: {display: 'none'},
        },
        Cell: ({row}: any) => <UserMessagesCell t={t} user={row.original} />,
      },
      {
        accessorKey: 'userRolePlatforms',
        header: t('userRoleHeader'),
        enableEditing: true,
        Edit: ({cell, row, column}: any) => (
          <EditTableRoleColumn t={t} cell={cell} row={row} column={column} />
        ),
        Cell: ({cell}: {cell: MRT_Cell<any>}) => (
          <>
            {cell
              .getValue<any>()
              ?.map(
                (d: {
                  role: {name: string};
                  platform: {name: string};
                  name?: string;
                }) => (
                  <Box sx={{display: 'flex', gap: '2ch', alignItems: 'center'}}>
                    <Chip label={`${d?.role?.name || d?.name}`} />
                  </Box>
                ),
              )}
          </>
        ),
      },
    ];

    if (platform?.group.groupPlan.plan.name === 'Free') {
      return filteredColumns.filter(
        column => column.accessorKey !== 'userMessages',
      );
    }

    return filteredColumns;
  }, [t, validationErrors]);

  return (
    <Container
      id='pricing'
      sx={{
        pt: {xs: 4, sm: 12},
        pb: {xs: 8, sm: 16},
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: {xs: 3, sm: 6},
      }}
    >
      <Box
        sx={{
          width: {sm: '100%', md: '60%'},
          textAlign: {sm: 'left', md: 'center'},
          display: 'flex',
          flexDirection: 'column',
          alignItems: {sm: 'flex-start', md: 'center'},
          gap: 1.5,
          py: 2,
        }}
      >
        <Typography
          component='h2'
          variant='h4'
          color='text.primary'
          fontWeight='bold'
        >
          {t('dashboardMenuUser')}
        </Typography>
        <Typography
          variant='body1'
          color='text.secondary'
          sx={{maxWidth: '80%', opacity: 0.9}}
        >
          {t('dashboardTableUserDescription')}
        </Typography>
      </Box>
      <Tooltip title={t('dashboardTableCreditTooltip')} arrow>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginBottom: '-1rem',
          }}
        >
          <Chip
            label={
              <Box sx={{display: 'flex', alignItems: 'center', gap: '4px'}}>
                <Typography variant='body2' color='primary'>
                  {platformUsage?.userCount}
                </Typography>
                /
                <Typography variant='body2' color='text.secondary'>
                  {platformUsage?.ruleObj.maxLimitUserCount}
                </Typography>
              </Box>
            }
            size='small'
            color='default'
            sx={{
              fontSize: '0.75rem',
              height: 24,
              paddingX: 1.2,
              backgroundColor: 'background.paper', // 讓它更融合背景
              border: '1px solid #ccc', // 添加邊框讓它更清楚
            }}
          />
        </Box>
      </Tooltip>

      <TWithProviders
        props={{
          createApi: async (data: PostUserDto & PutUserSetRoleDto) => {
            const user = await createUser(data);
            if (
              user &&
              data.userRolePlatforms &&
              data.userRolePlatforms.length
            ) {
              await updateUserSetRole(user?.data?.data?.id, {
                roleIds: data.userRolePlatforms.map(up => up.id),
              });
            }

            return user;
          },
          updateApi: async (
            id: string,
            data: PatchUserDto & PutUserSetRoleDto,
          ) => {
            const list = data?.userRolePlatforms?.map(up => up.id);
            await updateUserSetRole(id, {
              roleIds: list || [],
            });

            return updateUser(id, data);
          },
          getListApi: getUserList,
          deleteApi: deleteUser,
        }}
        columns={columns}
        validate={validate}
        setValidateErrors={setValidationErrors}
      ></TWithProviders>
      <Snackbar
        anchorOrigin={{vertical: state.vertical, horizontal: state.horizontal}}
        open={open}
        autoHideDuration={ALERT_HIDE_DURATION}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={state.type}
          variant='filled'
          sx={{width: '100%'}}
        >
          {state.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default function DashboardUser() {
  return (
    <>
      <Dashboard element={<UserContent />}></Dashboard>
    </>
  );
}
