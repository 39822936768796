import {Dispatch, SetStateAction, useState} from 'react';
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  type MRT_Row,
  type MRT_TableOptions,
  useMaterialReactTable,
  MRT_RowData,
  MRT_TableInstance,
} from 'material-react-table';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {BaseRO} from '../api/dto';
import {HttpStatusCode} from 'axios';
import {useTranslation} from 'react-i18next';
import NoPermissionAlert from './Alert/NoPermission';
import {Cancel, Cancel as CancelIcon, Close, Save} from '@mui/icons-material';
import AddCircleIcon from '@mui/icons-material/AddCircle';

export type MRT_ColumnDef_Add_Show<T extends MRT_RowData> = MRT_ColumnDef<T> & {
  show?: boolean;
};

type MaterialTableType = {
  createApi: Function;
  getListApi: Function;
  updateApi?: Function;
  deleteApi?: Function;
};

const DataElement = <T extends BaseRO>({
  props,
  columns,
  validate,
  onlyEditDeleteByIncludeIds,
  onlyEditDeleteByExcludeIds,
  displayMode = 'modal',
  setValidateErrors,
  renderActionsFunc,
  refreshFunc,
}: {
  props: MaterialTableType;
  columns: MRT_ColumnDef_Add_Show<T>[];
  validate: Function;
  onlyEditDeleteByIncludeIds?: string[];
  onlyEditDeleteByExcludeIds?: string[];
  displayMode?: 'row' | 'modal' | 'custom' | undefined;
  setValidateErrors: Dispatch<
    SetStateAction<Record<string, string | undefined>>
  >;
  renderActionsFunc?: (
    row: MRT_Row<T>,
    table: MRT_TableInstance<T>,
    openDeleteConfirmModal: (row: MRT_Row<T>) => void,
  ) => any;
  refreshFunc?: (refetch: Function) => void;
}) => {
  const {t} = useTranslation();
  const {createApi, updateApi, getListApi, deleteApi} = props;
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<MRT_Row<T> | null>(null);

  const {mutateAsync: createFunc, isPending: isCreatingFunc} =
    useCreate<T>(createApi);

  const {
    data: fetcheds = {data: {data: []}, status: HttpStatusCode.Conflict},
    isError: isLoadingError,
    isFetching,
    isLoading,
    refetch,
  } = useGetList(getListApi);
  const {mutateAsync: updateFunc, isPending: isUpdatingFunc} = useUpdate<T>(
    updateApi as any,
  );
  const {mutateAsync: deleteFunc, isPending: isDeletingFunc} = useDelete<T>(
    deleteApi as any,
  );

  const handleCreate: MRT_TableOptions<T>['onCreatingRowSave'] = async ({
    values,
    table,
  }) => {
    const newValidationErrors = validate(values);
    if (Object.values(newValidationErrors).some(error => error)) {
      setValidateErrors(newValidationErrors);
      return;
    }
    setValidateErrors({});
    await createFunc(values as any);
    table.setCreatingRow(null);
  };

  const handleSave: MRT_TableOptions<T>['onEditingRowSave'] = async ({
    values,
    table,
  }) => {
    const newValidationErrors = validate(values);
    if (Object.values(newValidationErrors).some(error => error)) {
      setValidateErrors(newValidationErrors);
      return;
    }
    setValidateErrors({});
    await updateFunc(values as any);
    table.setEditingRow(null);
  };

  const handleDeleteConfirm = async () => {
    if (selectedRow) {
      await deleteFunc(selectedRow.original.id);
    }
    setOpenConfirmDialog(false);
    setSelectedRow(null);
  };

  const openDeleteConfirmModal = (row: MRT_Row<T>) => {
    setSelectedRow(row);
    setOpenConfirmDialog(true);
  };

  if (refreshFunc) {
    refreshFunc(refetch);
  }

  const notShowColumnObj = columns.reduce((d, n) => {
    if (n.show === false) {
      return {...d, [n.accessorKey as string]: n.show};
    }

    return d;
  }, {});

  const table = useMaterialReactTable({
    columns,
    data:
      fetcheds.status === HttpStatusCode.Ok
        ? (fetcheds as any)?.data?.items || []
        : [],
    createDisplayMode: displayMode,
    editDisplayMode: displayMode,
    enableEditing: true,
    enableColumnOrdering: true,
    enableHiding: true,
    getRowId: row => row.id,
    muiToolbarAlertBannerProps: isLoadingError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: '500px',
      },
    },
    localization: {
      noRecordsToDisplay: t('materialReactTableNotFound'),
      actions: t('materialReactTableActions'),
    },
    onCreatingRowCancel: () => setValidateErrors({}),
    onCreatingRowSave: handleCreate,
    onEditingRowCancel: () => setValidateErrors({}),
    onEditingRowSave: handleSave,
    renderCreateRowDialogContent: ({table, internalEditComponents}: any) => {
      return (
        <>
          <DialogTitle
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {t('dashboardTableCreateBtn')}
            <IconButton onClick={() => table.setCreatingRow(null)}>
              <Close />
            </IconButton>
          </DialogTitle>
          <Divider />
          <DialogContent
            sx={{display: 'flex', flexDirection: 'column', gap: 2, p: 3}}
          >
            {internalEditComponents}
          </DialogContent>
          <Divider />
          <DialogActions sx={{justifyContent: 'space-between', p: 2}}>
            <Button
              onClick={() => table.setCreatingRow(null)}
              color='error'
              startIcon={<Cancel />}
            >
              {t('cancelBtn')}
            </Button>
            <Button
              onClick={async () => {
                const creatingRow = table.getState().creatingRow;
                if (!creatingRow) return;

                const values: Record<string, any> = creatingRow
                  .getAllCells()
                  .reduce((acc: any, cell: any) => {
                    acc[cell.column.id] = cell.getValue();
                    return acc;
                  }, {} as Record<string, any>);

                await table.options.onCreatingRowSave?.({
                  exitCreatingMode: () => table.setCreatingRow(null),
                  row: creatingRow,
                  table,
                  values,
                });
              }}
              color='primary'
              variant='contained'
              startIcon={<Save />}
            >
              {t('saveBtn')}
            </Button>
          </DialogActions>
        </>
      );
    },
    renderEditRowDialogContent: ({table, internalEditComponents}: any) => {
      return (
        <>
          <DialogTitle
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {t('dashboardTableEditBtn')}
            <IconButton onClick={() => table.setEditingRow(null)}>
              <Close />
            </IconButton>
          </DialogTitle>
          <Divider />
          <DialogContent
            sx={{display: 'flex', flexDirection: 'column', gap: 2, p: 3}}
          >
            {internalEditComponents}
          </DialogContent>
          <Divider />
          <DialogActions sx={{justifyContent: 'space-between', p: 2}}>
            <Button
              onClick={() => table.setEditingRow(null)}
              color='error'
              startIcon={<Cancel />}
            >
              {t('cancelBtn')}
            </Button>
            <Button
              onClick={async () => {
                const editingRow = table.getState().editingRow;
                if (!editingRow) return;
                const values: Record<string, any> = editingRow
                  .getAllCells()
                  .reduce((acc: any, cell: any) => {
                    acc[cell.column.id] = cell.getValue();
                    return acc;
                  }, {} as Record<string, any>);

                await table.options.onEditingRowSave?.({
                  exitEditingMode: () => table.setEditingRow(null),
                  row: editingRow,
                  table,
                  values,
                });
              }}
              color='primary'
              variant='contained'
              startIcon={<Save />}
            >
              {t('saveBtn')}
            </Button>
          </DialogActions>
        </>
      );
    },
    renderRowActions: ({row, table}) => {
      const isInInclude =
        onlyEditDeleteByIncludeIds &&
        onlyEditDeleteByIncludeIds.includes(row.id);
      const isInExclude =
        onlyEditDeleteByExcludeIds &&
        onlyEditDeleteByExcludeIds.includes(row.id);
      const isExcludedMode =
        onlyEditDeleteByExcludeIds && onlyEditDeleteByExcludeIds?.length > 0;

      if (renderActionsFunc) {
        return renderActionsFunc(row, table, openDeleteConfirmModal);
      }

      if (!onlyEditDeleteByIncludeIds && !onlyEditDeleteByExcludeIds) {
        return (
          <Box sx={{display: 'flex', gap: '1rem'}}>
            {updateApi && (
              <Tooltip title='Edit'>
                <IconButton onClick={() => table.setEditingRow(row)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
            {deleteApi && (
              <Tooltip title='Delete'>
                <IconButton
                  color='error'
                  onClick={() => openDeleteConfirmModal(row)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        );
      }

      if (isInInclude || (isExcludedMode && !isInExclude)) {
        return (
          <Box sx={{display: 'flex', gap: '1rem'}}>
            {updateApi && (
              <Tooltip title='Edit'>
                <IconButton onClick={() => table.setEditingRow(row)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
            {deleteApi && (
              <Tooltip title='Delete'>
                <IconButton
                  color='error'
                  onClick={() => openDeleteConfirmModal(row)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        );
      }
    },
    renderTopToolbarCustomActions: ({table}) => (
      <Button
        variant='contained'
        color='primary'
        onClick={() => {
          table.setCreatingRow(true);
        }}
        startIcon={<AddCircleIcon />}
        sx={{
          fontSize: '16px',
          textTransform: 'none',
          borderRadius: '8px',
          padding: '8px 16px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {t('dashboardTableCreateBtn')}
      </Button>
    ),
    state: {
      isLoading,
      isSaving: isCreatingFunc || isUpdatingFunc || isDeletingFunc,
      showAlertBanner: isLoadingError,
      showProgressBars: isFetching,
    },
    initialState: {
      columnVisibility: {
        ...notShowColumnObj,
      },
    },
  });

  if (
    fetcheds.status !== HttpStatusCode.Ok &&
    fetcheds.status !== HttpStatusCode.Conflict
  ) {
    return <NoPermissionAlert t={t} />;
  }

  return (
    <>
      <MaterialReactTable table={table} />

      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        aria-labelledby='delete-confirm-dialog'
        maxWidth='xs'
        fullWidth
        sx={{
          borderRadius: 2,
          boxShadow: 24,
        }}
      >
        <DialogContent>
          <Typography variant='h6' color='text.primary' gutterBottom>
            {t('dashboardConfirmMessage')}
          </Typography>
        </DialogContent>

        <DialogActions sx={{justifyContent: 'space-between'}}>
          <Button
            onClick={() => setOpenConfirmDialog(false)}
            variant='outlined'
            color='secondary'
            startIcon={<CancelIcon />}
            sx={{
              textTransform: 'none',
              borderColor: 'secondary.main',
              '&:hover': {
                borderColor: 'secondary.dark',
              },
            }}
          >
            {t('dashboardConfirmMessageN')}
          </Button>

          <Button
            onClick={handleDeleteConfirm}
            variant='contained'
            color='error'
            startIcon={<DeleteIcon />}
            sx={{
              textTransform: 'none',
              backgroundColor: 'error.main',
              '&:hover': {
                backgroundColor: 'error.dark',
              },
            }}
          >
            {t('dashboardConfirmMessageY')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

function useCreate<T extends BaseRO>(createApi: Function) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: T) => {
      const create = await createApi(data);
      if (create.status !== HttpStatusCode.Ok) {
        return Promise.reject(create?.data?.message || JSON.stringify(create));
      }
      return Promise.resolve(create);
    },
    onMutate: (newData: T) => {
      queryClient.setQueryData(['datas'], (prev: any) => {
        return [
          {
            ...newData,
            id: (Math.random() + 1).toString(36).substring(7),
          },
        ] as T[];
      });
    },
    onSettled: () => queryClient.invalidateQueries({queryKey: ['datas']}),
  });
}

function useGetList<T extends BaseRO>(getListApi: Function) {
  return useQuery<{data: T[]; status: string}>({
    queryKey: ['datas'],
    queryFn: async () => {
      const getList = await getListApi({limit: 1000});
      return getList;
    },
    refetchOnWindowFocus: false,
  });
}

function useUpdate<T extends BaseRO>(updateApi: Function) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: T) => {
      const {id, ...rest} = data;
      const update = await updateApi(id, rest);
      if (update.status !== HttpStatusCode.Ok) {
        Promise.reject(update?.data?.message || JSON.stringify(update));
      }
      return Promise.resolve(update);
    },
    onMutate: (newInfo: T) => {
      queryClient.setQueryData(['datas'], (prevList: any) => {
        return prevList?.data?.items?.map((prev: T) =>
          prev.id === newInfo.id ? newInfo : prev,
        );
      });
    },
    onSettled: () => queryClient.invalidateQueries({queryKey: ['datas']}),
  });
}

function useDelete<T extends BaseRO>(deleteApi: Function) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id: string) => {
      const deleteRow = await deleteApi(id);
      if (deleteRow.status !== HttpStatusCode.Ok) {
        Promise.reject(deleteRow?.data?.message || JSON.stringify(deleteRow));
      }
      return Promise.resolve(deleteRow);
    },
    onMutate: (id: string) => {
      queryClient.setQueryData(['datas'], (prev: any) => {
        return prev?.data?.items?.filter((data: T) => data.id !== id);
      });
    },
    onSettled: () => queryClient.invalidateQueries({queryKey: ['datas']}),
  });
}

const queryClient = new QueryClient();
const TWithProviders = ({
  props,
  columns,
  validate,
  onlyEditDeleteByIncludeIds,
  onlyEditDeleteByExcludeIds,
  displayMode,
  setValidateErrors,
  renderActionsFunc,
  refreshFunc,
}: {
  props: MaterialTableType;
  columns: MRT_ColumnDef<any>[];
  validate: Function;
  onlyEditDeleteByIncludeIds?: string[];
  onlyEditDeleteByExcludeIds?: string[];
  displayMode?: 'row' | 'modal' | 'custom' | undefined;
  setValidateErrors: Dispatch<
    SetStateAction<Record<string, string | undefined>>
  >;
  renderActionsFunc?: (
    row: MRT_Row<any>,
    table: MRT_TableInstance<any>,
    openDeleteConfirmModal: (row: MRT_Row<any>) => void,
  ) => any;
  refreshFunc?: (refetch: Function) => void;
}) => {
  return (
    <QueryClientProvider client={queryClient}>
      <DataElement
        onlyEditDeleteByIncludeIds={onlyEditDeleteByIncludeIds}
        onlyEditDeleteByExcludeIds={onlyEditDeleteByExcludeIds}
        props={props}
        columns={columns}
        renderActionsFunc={renderActionsFunc}
        validate={validate}
        displayMode={displayMode}
        refreshFunc={refreshFunc}
        setValidateErrors={setValidateErrors}
      />
    </QueryClientProvider>
  );
};

export default TWithProviders;

export const validateRequired = (value: string) => !!value?.length;
export const validateUrl = (value: string) =>
  !value?.length ||
  value
    .toLowerCase()
    .match(
      /(?:^|\s)((https?:\/\/)?(?:localhost|[\w-]+(?:\.[\w-]+)+)(:\d+)?(\/\S*)?)/,
    );

export const validateMoreThan20 = (value: string) =>
  !value || value.length > 20;

export const validateLengthMoreThanEqual = (value: string, min: number) =>
  value?.length >= min;
export const validateEmail = (email: string) =>
  !!email?.length &&
  email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

export const validateEmailListRequired = (
  value: {mailAddress: string}[],
): boolean => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  return (
    Array.isArray(value) &&
    value.length > 0 &&
    value.every(
      item =>
        typeof item.mailAddress === 'string' &&
        emailRegex.test(item.mailAddress),
    )
  );
};

export const validateEmailListOptional = (
  values: {mailAddress: string}[],
): boolean => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  return Array.isArray(values) && values.length
    ? values.every(
        item =>
          typeof item.mailAddress === 'string' &&
          emailRegex.test(item.mailAddress),
      )
    : true;
};

export const validateLoginType = (value: string) => {
  return (
    {
      email: true,
      google: true,
    }[value] ?? false
  );
};
