import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import Dashboard from '../../components/Dashboard';
import React, {ReactElement, useEffect, useMemo, useState} from 'react';
import 'react-quill/dist/quill.snow.css';
import TWithProviders, {
  MRT_ColumnDef_Add_Show,
  validateEmailListOptional,
  validateEmailListRequired,
  validateMoreThan20,
  validateRequired,
} from '../../components/MaterialTable';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  Alert,
  AlertColor,
  Chip,
  Snackbar,
  SnackbarOrigin,
  Tooltip,
} from '@mui/material';
import {ALERT_HIDE_DURATION} from '../../constant';
import {CreatedAtColumn} from '../../components/Date/CreatedAtColumn';
import InfiniteIcon from '@mui/icons-material/AllInclusive';
import {usePlatformUsage} from '../../context/platformUsage';
import {
  createPlatformEmail,
  deletePlatformEmail,
  getPlatformEmailList,
  updatePlatformEmail,
} from '../../api/platformEmail';
import EmailMultiSelect from '../../components/Email/EmailMultiSelect';
import ReactQuill from 'react-quill';
import EmailDisplay from '../../components/Email/EmailDisplay';
import {StatusColumn} from '../../components/EditTable/ViewTableStatusColumn';
dayjs.extend(utc);

function validate(data: any, t: any) {
  return {
    subject: !validateRequired(data.subject)
      ? t('dashboardPlatformSubjectRequired')
      : '',
    to: !validateEmailListRequired(data.to)
      ? t('dashboardPlatformToRequired')
      : '',
    cc: !validateEmailListOptional(data.cc)
      ? t('dashboardPlatformToRequired')
      : '',
    body_html: !validateMoreThan20(data.body_html)
      ? t('dashboardPlatformHtml20More')
      : '',
  };
}

interface State extends SnackbarOrigin {
  open: boolean;
  type: AlertColor;
  message: string;
}

function PlatformEmailContent(): ReactElement {
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const {t} = useTranslation();
  const {platformUsage} = usePlatformUsage();

  const [state, setState] = React.useState<State>({
    open: false,
    type: 'success',
    message: '',
    vertical: 'bottom',
    horizontal: 'right',
  });
  const {open} = state;

  const handleClose = () => {
    setState({...state, open: false});
  };

  const columns = useMemo<MRT_ColumnDef_Add_Show<any>[]>(() => {
    const filteredColumns = [
      {
        accessorKey: 'id',
        header: 'Id',
        enableEditing: false,
        show: false,
        size: 80,
      },
      {
        accessorKey: 'to',
        header: t('toHeader'),
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.to,
          helperText: validationErrors?.to,
          sx: {display: 'none'},
          onFocus: () => {
            return setValidationErrors({
              ...validationErrors,
              to: undefined,
            });
          },
        },
        Cell: ({column, row, cell}: any) => (
          <>
            <EmailDisplay column={column} cell={cell} t={t} row={row} />
          </>
        ),
        Edit: ({cell, row, column}: any) => (
          <div style={{padding: '8px', minWidth: '250px'}}>
            <span
              style={{
                display: 'block',
                marginBottom: '4px',
                color: '#757575',
                fontSize: '14px',
              }}
            >
              {t('toHeader')} <span style={{color: 'red'}}>*</span>
            </span>
            <EmailMultiSelect cell={cell} t={t} row={row} column={column} />
          </div>
        ),
      },
      {
        accessorKey: 'cc',
        header: t('ccHeader'),

        muiEditTextFieldProps: {
          required: false,
          error: !!validationErrors?.cc,
          helperText: validationErrors?.cc,
          sx: {display: 'none'},
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              cc: undefined,
            }),
        },
        Cell: ({column, row, cell}: any) => (
          <>
            <EmailDisplay column={column} cell={cell} t={t} row={row} />
          </>
        ),
        Edit: ({cell, column, row}: any) => (
          <div style={{padding: '8px', minWidth: '250px'}}>
            <span
              style={{
                display: 'block',
                marginBottom: '4px',
                color: '#757575',
                fontSize: '14px',
              }}
            >
              {t('ccHeader')} <span style={{fontSize: '12px'}}>(Optional)</span>
            </span>
            <EmailMultiSelect cell={cell} t={t} row={row} column={column} />
          </div>
        ),
      },
      {
        accessorKey: 'subject',
        header: t('subjectHeader'),
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.subject,
          helperText: validationErrors?.subject,
          onFocus: () => {
            return setValidationErrors({
              ...validationErrors,
              subject: undefined,
            });
          },
        },
      },
      {
        accessorKey: 'status',
        header: t('statusHeader'),
        editVariant: 'select' as any,
        editSelectOptions: [
          {labelKey: t('statusFinish'), value: 'Finish'},
          {labelKey: t('statusFail'), value: 'Fail'},
          {labelKey: t('statusPending'), value: 'Pending'},
        ],
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.status,
          helperText: validationErrors?.status,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              status: undefined,
            }),
        },
        Cell: ({cell}: any) => <StatusColumn t={t} cell={cell} />,
        accessorFn: (row: any) => {
          return row.status;
        },
      },
      {
        accessorKey: 'bodyHtml',
        header: t('htmlHeader'),
        show: false,
        Edit: ({cell, column, row}: any) => {
          const [htmlValue, setHtmlValue] = useState(cell.getValue() || ''); // 确保有默认值
          const [error, setError] = useState(false);
          const [errorMessage, setErrorMessage] = useState('');

          useEffect(() => {
            row._valuesCache[column.id] = htmlValue;
          }, [htmlValue]); // 监听 htmlValue 变化，确保 valuesCache 被正确更新

          const handleHtmlChange = (newValue: string) => {
            setHtmlValue(newValue);
            validateHtml(newValue);
          };

          const validateHtml = (value: string) => {
            if (!value || value.replace(/<[^>]*>/g, '').trim().length < 20) {
              setError(true);
              setErrorMessage('內容至少需要 20 個字');
            } else {
              setError(false);
              setErrorMessage('');
            }
          };

          return (
            <Box>
              <ReactQuill
                theme='snow'
                value={htmlValue}
                onChange={handleHtmlChange}
              />
              {error && (
                <Box sx={{color: 'red', fontSize: '12px', marginTop: '4px'}}>
                  {errorMessage}
                </Box>
              )}
            </Box>
          );
        },
      },

      {
        accessorKey: 'createdAt',
        header: t('createdAtHeader'),
        enableEditing: false,
        Cell: ({cell}: any) => <CreatedAtColumn cell={cell} />,
      },
    ];

    return filteredColumns;
  }, [t, validationErrors]);

  return (
    <Container
      id='pricing'
      sx={{
        pt: {xs: 4, sm: 12},
        pb: {xs: 8, sm: 16},
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: {xs: 3, sm: 6},
      }}
    >
      <Box
        sx={{
          width: {sm: '100%', md: '60%'},
          textAlign: {sm: 'left', md: 'center'},
          display: 'flex',
          flexDirection: 'column',
          alignItems: {sm: 'flex-start', md: 'center'},
          gap: 1.5,
          py: 2,
        }}
      >
        <Typography
          component='h2'
          variant='h4'
          color='text.primary'
          fontWeight='bold'
        >
          {t('dashboardPlatformEmail')}
        </Typography>
        <Typography
          variant='body1'
          color='text.secondary'
          sx={{maxWidth: '80%', opacity: 0.9}}
        >
          {t('dashboardTablePlatformEmailDescription')}
        </Typography>
      </Box>
      <Tooltip title={t('dashboardTableCreditTooltip')} arrow>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginBottom: '-1rem',
          }}
        >
          <Chip
            label={
              <Box sx={{display: 'flex', alignItems: 'center', gap: '4px'}}>
                <Typography variant='body2' color='primary'>
                  {platformUsage?.platformEmailCount}
                </Typography>
                /
                <Typography variant='body2' color='text.secondary'>
                  {platformUsage?.ruleObj?.maxLimitPlatformEmailCount || (
                    <InfiniteIcon fontSize='small' />
                  )}
                </Typography>
              </Box>
            }
            size='small'
            color='default'
            sx={{
              fontSize: '0.75rem',
              height: 24,
              paddingX: 1.2,
              backgroundColor: 'background.paper',
              border: '1px solid #ccc',
            }}
          />
        </Box>
      </Tooltip>

      <TWithProviders
        props={{
          createApi: createPlatformEmail,
          getListApi: getPlatformEmailList,
          deleteApi: deletePlatformEmail,
        }}
        columns={columns}
        validate={(data: any) => validate(data, t)}
        setValidateErrors={setValidationErrors}
      ></TWithProviders>
      <Snackbar
        anchorOrigin={{vertical: state.vertical, horizontal: state.horizontal}}
        open={open}
        autoHideDuration={ALERT_HIDE_DURATION}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={state.type}
          variant='filled'
          sx={{width: '100%'}}
        >
          {state.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default function DashboardPlatformEmail() {
  return (
    <>
      <Dashboard element={<PlatformEmailContent />}></Dashboard>
    </>
  );
}
