import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import LanguageIcon from '@mui/icons-material/Language';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import {i18n} from 'i18next';

export const LanguageObject: {[k: string]: string} = {
  'en-US': 'English',
  'zh-CN': '简体中文',
  'zh-TW': '繁體中文',
  'ja-JP': '日本語',
  'ms-MY': 'Melayu',
};

export default function LanguageSelector({i18n}: {i18n: i18n}) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const localeI18n = localStorage.getItem('i18n');
  const localstorageObject: {[k: string]: string} = {
    English: 'en-US',
    简体中文: 'zh-CN',
    繁體中文: 'zh-TW',
    日本語: 'ja-JP',
    Melayu: 'ms-MY',
  };

  const indexI18n = Object.keys(LanguageObject).findIndex(
    d => d === localeI18n,
  );
  const [selectedIndex, setSelectedIndex] = React.useState(indexI18n);
  const languageOptions = Object.values(LanguageObject);

  React.useEffect(() => {
    i18n.changeLanguage(localeI18n as string);
  }, []);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    localStorage.setItem('i18n', localstorageObject[languageOptions[index]]);
    i18n.changeLanguage(localstorageObject[languageOptions[index]]);
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        variant='text'
        ref={anchorRef}
        aria-label='Button group with a nested menu'
        sx={{
          backgroundColor: 'transparent',
        }}
      >
        <Button
          size='small'
          sx={{
            minWidth: '36px',
            p: '6px',
            borderRadius: '8px',
            backdropFilter: 'blur(8px)', // 模糊效果
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)', // 阴影增加层次
            transition: 'all 0.3s ease',
            '&:hover': {
              background: 'linear-gradient(90deg, #2196F3, #21CBF3)',
              boxShadow: '0px 0px 12px rgba(33, 203, 243, 0.8)',
            },
          }}
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label='select language'
          aria-haspopup='menu'
          onClick={handleToggle}
        >
          <LanguageIcon sx={{color: '#FFFFFF', fontSize: '24px'}} />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1200,
        }}
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
      >
        {({TransitionProps, placement}) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper
              sx={{
                backgroundColor: '#1E1E1E', // 深色背景
                border: '1px solid rgba(255, 255, 255, 0.1)',
                boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.5)',
                borderRadius: '8px',
                overflow: 'hidden',
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem>
                  {languageOptions.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex}
                      onClick={event => handleMenuItemClick(event, index)}
                      sx={{
                        color: '#FFFFFF',
                        '&:hover': {
                          backgroundColor: 'rgba(33, 150, 243, 0.2)',
                          borderLeft: '4px solid #2196F3',
                        },
                      }}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}
