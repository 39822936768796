import * as React from 'react';
import {useEffect, useState} from 'react';
import Typography from '@mui/material/Typography';
import Title from './Title';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import {getPlatformById} from '../api/platform';
import {getUserById, getUserMe} from '../api/user';
import {TFunction} from 'i18next';
import {UserDto} from '../api/dto/user';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import TimerOffIcon from '@mui/icons-material/TimerOff';
import NoPermissionAlert from './Alert/NoPermission';
import {UserInfoCell} from './User/TabelCell';

export default function PlatformPlan({
  t,
}: {
  t: TFunction<'translation', undefined>;
}) {
  const [userId, setUserId] = useState<string | null>(null);
  const [user, setUser] = useState<UserDto | null>(null);
  const [hasPlatformAccess, setHasPlatformAccess] = useState(true);
  const [loading, setLoading] = useState(true);
  const platformId = localStorage.getItem('platformId') || '';
  const [loginUser, setLoginUser] = useState<UserDto>();

  useEffect(() => {
    const fetchUserMe = async () => {
      try {
        const userResponse = await getUserMe();
        if (userResponse?.data?.data) {
          setLoginUser(userResponse.data.data);
        }
      } catch (error) {}
    };

    fetchUserMe();
  }, []);

  useEffect(() => {
    const fetchPlatform = async () => {
      try {
        const platformResponse = await getPlatformById();
        if (platformResponse?.data?.data) {
          setUserId(platformResponse.data.data.userId);
        } else {
          setHasPlatformAccess(false);
        }
      } catch (error) {
        setHasPlatformAccess(false);
      }
    };

    fetchPlatform();
  }, [platformId]);

  useEffect(() => {
    const fetchUserPlan = async () => {
      if (!userId) {
        setLoading(false);
        return;
      }
      try {
        const userResponse = await getUserById(userId);
        if (userResponse?.data?.data) {
          setUser(userResponse.data.data);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchUserPlan();
  }, [userId]);

  if (loading) {
    return (
      <>
        <Title>{t('dashboardAccountPlatformPlanTitle')}</Title>
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='200px'
        >
          <CircularProgress />
        </Box>
      </>
    );
  }

  const getPlanColor = (plan: string) => {
    switch (plan) {
      case 'Basic':
        return {color: 'green', icon: <CheckCircleIcon />};
      case 'Pro':
        return {color: 'blue', icon: <CheckCircleIcon />};
      case 'Premium':
        return {color: 'gold', icon: <CheckCircleIcon />};
      case 'Free':
        return {color: 'gray', icon: <CancelIcon />};
      default:
        return {color: 'black', icon: <CancelIcon />};
    }
  };

  return (
    <React.Fragment>
      <Title>{t('dashboardAccountPlatformPlanTitle')}</Title>
      {hasPlatformAccess ? (
        <Box display='flex' alignItems='center' justifyContent='flex-start'>
          <UserInfoCell t={t} user={user} showType='icon' />
          <Typography component='p' variant='h6'>
            {t('dashboardAccountPlatformPlanOnwer')}: {user?.username || 'N/A'}{' '}
            {user?.id === loginUser?.id ? (
              <Typography
                component='span'
                variant='h6'
                color='primary'
                fontWeight='bold'
              >
                ({t('dashboardCreatorIsLoginUser')})
              </Typography>
            ) : (
              <></>
            )}
          </Typography>
        </Box>
      ) : (
        <NoPermissionAlert t={t} />
      )}

      {hasPlatformAccess && user?.group?.groupPlan?.plan && (
        <Typography
          component='p'
          variant='h6'
          sx={{
            color: getPlanColor(user?.group?.groupPlan?.plan.name).color,
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            mt: 2,
          }}
        >
          <IconButton disabled>
            {getPlanColor(user?.group?.groupPlan?.plan.name).icon}
          </IconButton>
          {t('dashboardAccountPlatformPlanPlan')}:{' '}
          {user?.group?.groupPlan?.plan.name || 'No Plan'}
        </Typography>
      )}

      {hasPlatformAccess && user?.group?.groupPlan?.expirationAt && (
        <Typography color='text.secondary' sx={{mt: 2}}>
          <IconButton disabled>
            <TimerOffIcon />
          </IconButton>
          {t('dashboardAccountPlatformPlanExpireDate')}:{' '}
          {new Date(user?.group?.groupPlan?.expirationAt).toLocaleDateString()}
        </Typography>
      )}
    </React.Fragment>
  );
}
