import * as React from 'react';
import {alpha, keyframes} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeInDelayed = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export default function Hero() {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const handleStart = () => {
    navigate('/dashboard');
  };

  return (
    <Box
      id='hero'
      sx={theme => ({
        width: '100%',
        minHeight: '100vh',
        backgroundImage:
          theme.palette.mode === 'light'
            ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
            : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        alignItems: 'center',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          pt: {xs: 10, sm: 16},
          pb: {xs: 8, sm: 12},
        }}
      >
        <Stack spacing={3} sx={{width: {xs: '100%', sm: '100%'}}}>
          {/* 主標題 */}
          <Typography
            variant='h2'
            sx={{
              display: 'flex',
              flexDirection: {xs: 'column', md: 'row'},
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              fontSize: 'clamp(2rem, 6vw, 3.5rem)',
              fontWeight: 600,
              flexWrap: 'wrap',
              gap: 1,
              animation: `${fadeIn} 1s ease-in-out`, // 主標題動畫
            }}
          >
            {t('homePageTitle')}&nbsp;
            <Box
              component='span'
              sx={{
                fontSize: 'clamp(2.5rem, 8vw, 4.5rem)',
                fontWeight: 700,
                color: theme =>
                  theme.palette.mode === 'light'
                    ? 'primary.main'
                    : 'primary.light',
                whiteSpace: {xs: 'normal', md: 'nowrap'},
                opacity: 0, // 初始設置為透明
                animation: `${fadeInDelayed} 1s ease-in-out 0.5s forwards`, // 延遲 0.5秒顯示第二段文字
              }}
            >
              {t('homePageTitleProduct')}
            </Box>
          </Typography>
          ;{/* 按鈕組 */}
          <Stack
            direction={{xs: 'column', sm: 'row'}}
            spacing={2}
            sx={{pt: 3, alignSelf: 'center'}}
          >
            <Button
              variant='contained'
              color='primary'
              size='large'
              onClick={handleStart}
              sx={{px: 4, py: 1.5, fontSize: '1rem'}}
            >
              {t('homePageTitleStartNowBtn')}
            </Button>
          </Stack>
          {/* 提示文字 */}
          <Typography variant='caption' textAlign='center' sx={{opacity: 0.8}}>
            {t('homePageTitleNotice')}
            <Link href='#' color='primary' underline='hover' sx={{ml: 0.5}}>
              {t('homePageTitleTermAndCondition')}
            </Link>
            。
          </Typography>
        </Stack>

        {/* 背景圖片 */}
        <Box
          id='image'
          sx={theme => ({
            mt: {xs: 4, sm: 8},
            alignSelf: 'center',
            height: {xs: 280, sm: 520},
            width: '100%',
            backgroundImage: 'url("/homepage_banner.jpg")',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            borderRadius: '20px',
            outline: '2px solid',
            outlineColor:
              theme.palette.mode === 'light'
                ? alpha('#BFCCD9', 0.3)
                : alpha('#9CCCFC', 0.1),
            boxShadow:
              theme.palette.mode === 'light'
                ? `0 8px 24px ${alpha('#9CCCFC', 0.2)}`
                : `0 12px 36px ${alpha('#033363', 0.3)}`,
            transition: 'transform 0.3s ease-out, box-shadow 0.3s ease-out',
            '&:hover': {
              transform: 'scale(1.02)',
              boxShadow:
                theme.palette.mode === 'light'
                  ? `0 12px 36px ${alpha('#9CCCFC', 0.3)}`
                  : `0 16px 48px ${alpha('#033363', 0.4)}`,
            },
          })}
        />
      </Container>
    </Box>
  );
}
