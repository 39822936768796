import * as React from 'react';
import {styled, createTheme, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar, {AppBarProps as MuiAppBarProps} from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LogoutTwoToneIcon from '@mui/icons-material/LogoutTwoTone';
import {DynamicMenu, DynamicMobileMenu} from './listItems';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import LanguageSelector from './LanguageSelector';
import {SwipeableDrawer, useMediaQuery} from '@mui/material';
import NotificationButton from './User/Notification';
import {PlatformProvider, usePlatform} from '../context/platform';
import {ModuleProvider, useModule} from '../context/module';
import {UserProvider, useUser} from '../context/user';
import CreditRecharge from './Credit/CreditRecharge';
import AddIcon from '@mui/icons-material/Add';
import {
  PlatformCreditProvider,
  usePlatformCredit,
} from '../context/platformCredit';
import CreditHistoryDialog from './Credit/CreditHistoryDialog';
import {PlatformUsageProvider} from '../context/platformUsage';

const drawerWidth: number = 240;
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<AppBarProps>(({theme, open}) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  [theme.breakpoints.down('sm')]: {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  ...(open && {
    [theme.breakpoints.up('sm')]: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  }),
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: prop => prop !== 'open'})(
  ({theme, open}) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const defaultTheme = createTheme();

function _Dashboard({element}: {element: React.ReactElement}) {
  const {t, i18n} = useTranslation();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const localeI18n = localStorage.getItem('i18n');
  const [open, setOpen] = React.useState(
    localStorage.getItem('drawerOpen') === 'true',
  );
  const [openCredit, setOpenCredit] = React.useState<boolean>(false);
  const {platform} = usePlatform();
  const {modules} = useModule();
  const {platformCredit} = usePlatformCredit();
  const [clicked, setClicked] = React.useState(false);

  const handleClick = () => {
    setClicked(true);
    setOpenCredit(true);
    setTimeout(() => {
      setClicked(false);
    }, 100);
  };
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const navigate = useNavigate();
  const toggleDrawer = () => {
    const newOpenState = !open;
    setOpen(newOpenState);
    localStorage.setItem('drawerOpen', String(newOpenState));
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  // 關閉對話框
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('platform');
    localStorage.removeItem('user');
    localStorage.removeItem('modules');
    localStorage.removeItem('platformUsage');
    localStorage.removeItem('platformCredit');
    localStorage.removeItem('platformCredit:ai');
    navigate('/');
  };

  React.useEffect(() => {
    if (localeI18n) i18n.changeLanguage(localeI18n);
  }, [localeI18n, i18n]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{display: 'flex'}}>
        <CssBaseline />
        <AppBar position='fixed' open={open}>
          <Toolbar sx={{pr: '24px'}}>
            <IconButton
              edge='start'
              color='inherit'
              aria-label='open drawer'
              onClick={toggleDrawer}
              sx={theme => ({
                marginRight: '36px',
                [theme.breakpoints.down('sm')]: {
                  display: 'block',
                },
                [theme.breakpoints.up('sm')]: {
                  ...(open && {display: 'none'}),
                },
              })}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component='h1'
              variant='h6'
              color='inherit'
              noWrap
              sx={{flexGrow: 1}}
            >
              {t('dashboardTitle')}
            </Typography>

            {platformCredit?.credits ? (
              <Toolbar sx={{pr: '24px'}}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '8px 16px', // 內邊距
                    borderRadius: '12px', // 圓角
                    border: '1px solid rgba(255, 255, 255, 0.3)', // 微微邊框
                    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)', // 輕微陰影
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                      transform: clicked ? 'scale(0.88)' : 'scale(1)',
                      transition: 'transform 0.1s ease',
                    }}
                    onClick={handleClick}
                  >
                    <Typography
                      variant='body1'
                      sx={{
                        fontSize: 22,
                        fontWeight: '500',
                        color: 'white',
                        letterSpacing: '0.5px',
                        lineHeight: '1.5',
                        textShadow: '0px 2px 5px rgba(0, 0, 0, 0.3)',
                        ml: 2,
                      }}
                    >
                      {platformCredit?.credits} {/* 顯示信用額度 */}
                    </Typography>

                    <img
                      src='/credit.png'
                      alt='credit icon'
                      style={{
                        width: '24px',
                        height: '24px',
                        objectFit: 'contain',
                        marginLeft: '8px',
                      }}
                    />
                  </Box>

                  <IconButton
                    color='inherit'
                    onClick={handleDialogOpen}
                    sx={{
                      ml: 1,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <AddIcon sx={{fontSize: 20}} /> {/* 調整 AddIcon 的大小 */}
                  </IconButton>
                </Box>

                {/* 充值選項對話框 */}
                <CreditRecharge
                  t={t}
                  open={dialogOpen}
                  onClose={handleDialogClose}
                  layout='vertical'
                />
                <CreditHistoryDialog
                  t={t}
                  open={openCredit}
                  onClose={() => setOpenCredit(false)}
                />
              </Toolbar>
            ) : (
              <></>
            )}

            <LanguageSelector i18n={i18n} />
            <NotificationButton t={t} />
            <IconButton color='inherit' onClick={handleLogout}>
              <LogoutTwoToneIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <>
          {/* 桌面版選單 */}
          {!isMobile && (
            <Drawer variant='permanent' anchor='left' open={open}>
              <Toolbar
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  px: [1],
                }}
              >
                <Typography marginRight='auto'>
                  <Link href={platform?.homePageUrl ?? '/'}>
                    <img
                      src={platform?.icon || '/logo-symbol.png'}
                      alt='brand-logo'
                      width={60}
                      height={50}
                    />
                  </Link>
                </Typography>
                <IconButton onClick={toggleDrawer}>
                  <ChevronLeftIcon />
                </IconButton>
              </Toolbar>
              <Divider />
              <List component='nav'>
                {<DynamicMenu open={open} modules={modules} t={t} />}
              </List>
            </Drawer>
          )}

          {/* 手機版選單 */}
          {isMobile && (
            <SwipeableDrawer
              anchor='bottom'
              open={open}
              onClose={toggleDrawer}
              onOpen={toggleDrawer}
            >
              <List
                sx={{
                  display: 'flex',
                  overflowX: 'auto', // 啟用水平滾動
                  whiteSpace: 'nowrap', // 確保不換行，防止元素折行導致消失
                  scrollBehavior: 'smooth',
                  WebkitOverflowScrolling: 'touch',
                }}
              >
                <DynamicMobileMenu modules={modules} t={t} />
              </List>
            </SwipeableDrawer>
          )}
        </>

        <Box
          component='main'
          sx={theme => ({
            flexGrow: 1,
            [theme.breakpoints.down('sm')]: {
              marginTop: 5,
            },
          })}
        >
          {element}
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default function Dashboard({element}: {element: React.ReactElement}) {
  return (
    <PlatformProvider>
      <UserProvider>
        <PlatformCreditProvider type='ai'>
          <PlatformUsageProvider>
            <ModuleProvider>
              <_Dashboard element={element} />
            </ModuleProvider>
          </PlatformUsageProvider>
        </PlatformCreditProvider>
      </UserProvider>
    </PlatformProvider>
  );
}
