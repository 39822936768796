import {Box, Chip, Tooltip} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel'; // 使用 Material UI 的 X 图标

const EmailDisplay = ({t, column, cell, row}: any) => {
  const emails = row.original[cell?.column?.id];

  return (
    <Box sx={{marginBottom: '10px'}}>
      {emails && emails.length > 0 ? (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '8px',
            alignItems: 'center',
            padding: '6px 8px',
            backgroundColor: '#f5f5f5',
            borderRadius: '8px',
          }}
        >
          {emails.map((email: {mailAddress: string}, index: number) => {
            const displayText = email.mailAddress?.trim()
              ? email.mailAddress
              : 'No email provided';

            return (
              <Tooltip
                key={index}
                title={email.mailAddress || 'Email not available'}
              >
                <Chip
                  key={index}
                  label={displayText}
                  sx={{
                    backgroundColor: email.mailAddress ? '#e3f2fd' : '#f8d7da',
                    color: email.mailAddress ? '#0277bd' : '#721c24',
                    fontSize: '14px',
                    borderRadius: '8px',
                    fontWeight: email.mailAddress ? 'normal' : 'bold',
                  }}
                />
              </Tooltip>
            );
          })}
        </Box>
      ) : (
        <CancelIcon
          sx={{
            color: '#222c22',
            fontSize: '26px',
          }}
        />
      )}
    </Box>
  );
};

export default EmailDisplay;
