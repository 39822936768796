import React, {ReactElement, useEffect, useState} from 'react';
import {
  Box,
  Container,
  Typography,
  Paper,
  Divider,
  Link,
  Alert,
  AlertColor,
  Snackbar,
  SnackbarOrigin,
  IconButton,
  CircularProgress,
  Button,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import UploadIcon from '@mui/icons-material/Upload';
import {useTranslation} from 'react-i18next';
import {HttpStatusCode} from 'axios';
import Dashboard from '../../../components/Dashboard';
import {ALERT_HIDE_DURATION} from '../../../constant';
import {getPlatformById, uploadPlatformIcon} from '../../../api/platform';
import {getUserById} from '../../../api/user';
import {UserDto} from '../../../api/dto/user';
import {PlatformDto} from '../../../api/dto/platform';
import PolicyIcon from '@mui/icons-material/Policy';
import NoPermissionAlert from '../../../components/Alert/NoPermission';

interface State extends SnackbarOrigin {
  open: boolean;
  type: AlertColor;
  message: string;
}

function PolicyInfoRow({label, value}: {label: string; value: string}) {
  return (
    <Box sx={{display: 'flex', alignItems: 'center', gap: 1, mb: 3}}>
      <Box sx={{flexGrow: 1}}>
        <Typography variant='subtitle2' color='text.secondary'>
          {label}
        </Typography>
        {value ? (
          <Link href={value} target='_blank' rel='noopener noreferrer'>
            <IconButton aria-label='policy'>
              <PolicyIcon color='primary' />
            </IconButton>
          </Link>
        ) : (
          <Typography variant='body1' color='text.primary'>
            N/A
          </Typography>
        )}
      </Box>
      <Divider sx={{mt: 1}} />
    </Box>
  );
}

function PlatformInfoRow({
  label,
  value,
}: {
  label: string;
  value: string | number | boolean;
}) {
  return (
    <Box sx={{display: 'flex', alignItems: 'center', gap: 1, mb: 2}}>
      <Box sx={{flexGrow: 1}}>
        <Typography variant='subtitle2' color='text.secondary'>
          {label}
        </Typography>
        <Typography
          variant='body1'
          color='text.primary'
          sx={{fontWeight: 'medium'}}
        >
          {typeof value === 'boolean' ? (value ? 'Yes' : 'No') : value || 'N/A'}
        </Typography>
      </Box>
      <Divider sx={{mt: 1}} />
    </Box>
  );
}

function InfoContent(): ReactElement {
  const {t} = useTranslation();
  const [platformData, setPlatformData] = useState<PlatformDto>();
  const [userData, setUserData] = useState<UserDto>();
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState<State>({
    open: false,
    type: 'success',
    message: '',
    vertical: 'bottom',
    horizontal: 'right',
  });
  const [uploading, setUploading] = useState(false);

  const {open} = state;

  const handleClick = (type: AlertColor, message: string) => {
    setState({...state, type, message, open: true});
  };

  const handleClose = () => {
    setState({...state, open: false});
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    setUploading(true);
    try {
      const response = await uploadPlatformIcon({file: file});
      if (response?.status === HttpStatusCode.Ok) {
        const updatedPlatformData = {...platformData, icon: response.data.icon};
        setPlatformData(updatedPlatformData as PlatformDto);
        handleClick('success', t('dashboardPlatformInfoUploadingIconSuccess'));
      } else {
        handleClick('error', JSON.stringify(response?.data));
      }
    } catch (error) {
      handleClick('error', JSON.stringify(error));
    } finally {
      setUploading(false);
    }
  };

  // 取得平台與用戶資料
  useEffect(() => {
    const fetchPlatformAndUser = async () => {
      try {
        const platformResponse = await getPlatformById();
        const platform = platformResponse && platformResponse.data;
        if (
          platformResponse &&
          platformResponse.status === HttpStatusCode.Unauthorized
        ) {
          handleClick('error', JSON.stringify(platformResponse?.data));
        }
        if (platform?.data?.userId) {
          const userResponse = await getUserById(platform.data.userId);
          setUserData(userResponse?.data.data);
        }

        setPlatformData(platform?.data);
      } catch (error) {
        handleClick(
          'error',
          t('failedToFetchData') || 'Failed to fetch platform data',
        );
      } finally {
        setLoading(false);
      }
    };

    fetchPlatformAndUser();
  }, [t]);

  if (loading) {
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        minHeight='50vh'
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container
      sx={{
        pt: {xs: 4, sm: 8},
        pb: {xs: 6, sm: 12},
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: {xs: 2, sm: 4},
      }}
    >
      <Paper
        elevation={3}
        sx={{
          p: {xs: 4, sm: 6},
          maxWidth: 600,
          width: '100%',
          borderRadius: 3,
          backgroundColor: 'background.paper',
        }}
      >
        <Typography
          component='h2'
          variant='h4'
          color='text.primary'
          sx={{mb: 4, textAlign: 'center', fontWeight: 'bold'}}
        >
          {t('dashboardPlatformInfo')}
        </Typography>

        {(platformData && (
          <>
            {platformData.icon && (
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                mb={2}
              >
                <img
                  src={platformData.icon}
                  alt={t('platformIcon') || 'Platform Icon'}
                  style={{
                    maxWidth: '60%',
                    height: 'auto',
                    borderRadius: '8px',
                  }}
                />
              </Box>
            )}

            <Button
              variant='contained'
              component='label'
              startIcon={<UploadIcon />}
              disabled={uploading}
              sx={{mb: 2}}
            >
              {uploading
                ? t('dashboardPlatformInfoUploadingIcon')
                : t('dashboardPlatformInfoUploadIcon')}
              <input
                type='file'
                hidden
                accept='image/*'
                onChange={handleFileChange}
              />
            </Button>

            <PlatformInfoRow
              label={t('dashboardPlatformInfoPlatformName')}
              value={platformData.name}
            />
            <PlatformInfoRow
              label={t('dashboardPlatformInfoPlatformUID')}
              value={platformData.userId}
            />
            <PolicyInfoRow
              label={t('dashboardPlatformInfoPlatformPolicyUrl')}
              value={platformData?.policyUrl || ''}
            />
            <PlatformInfoRow
              label={t('dashboardPlatformInfoPlatformHash')}
              value={platformData.hash}
            />
          </>
        )) || (
          <>
            <NoPermissionAlert t={t} />{' '}
          </>
        )}

        {userData?.group?.groupPlan && (
          <>
            <PlatformInfoRow
              label={t('dashboardPlatformInfoPlanName')}
              value={userData?.group?.groupPlan?.plan?.name || ''}
            />
            <PlatformInfoRow
              label={t('dashboardPlatformInfoPlanExpired')}
              value={
                isNaN(
                  new Date(
                    userData?.group?.groupPlan?.expirationAt || '',
                  ).getTime(),
                ) || !userData?.group?.groupPlan?.expirationAt
                  ? t('dashboardPlatformInfoPlanForever')
                  : new Date(
                      userData?.group?.groupPlan?.expirationAt,
                    ).toLocaleDateString()
              }
            />
          </>
        )}

        <Snackbar
          anchorOrigin={{
            vertical: state.vertical,
            horizontal: state.horizontal,
          }}
          open={open}
          autoHideDuration={ALERT_HIDE_DURATION}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={state.type}
            variant='filled'
            sx={{width: '100%'}}
          >
            {state.message}
          </Alert>
        </Snackbar>
      </Paper>
    </Container>
  );
}

export default function DashboardPlatformInfo() {
  return <Dashboard element={<InfoContent />} />;
}
