import React, {createContext, useContext, useEffect, useState} from 'react';
import {PlatformDto} from '../api/dto/platform';
import {getPlatformPublic} from '../api/platform';
import {HttpStatusCode} from 'axios';

interface PlatformContextType {
  platform?: PlatformDto;
  setPlatform: (platform?: PlatformDto) => void;
}

const PlatformContext = createContext<PlatformContextType | undefined>(
  undefined,
);

export const PlatformProvider = ({children}: {children: React.ReactNode}) => {
  const [platform, setPlatform] = useState<PlatformDto | undefined>(() => {
    const cachedPlatform = localStorage.getItem('platform');
    return cachedPlatform ? JSON.parse(cachedPlatform) : undefined;
  });

  useEffect(() => {
    if (!platform) {
      getPlatformPublic()?.then(d => {
        setPlatform(d.data?.data);
        if (d.status === HttpStatusCode.Ok) {
          localStorage.setItem('platform', JSON.stringify(d.data?.data));
        }
      });
    }
  }, [platform]);

  return (
    <PlatformContext.Provider value={{platform, setPlatform}}>
      {children}
    </PlatformContext.Provider>
  );
};

export const usePlatform = () => {
  const context = useContext(PlatformContext);
  if (!context) {
    throw new Error('usePlatform must be used within a PlatformProvider');
  }
  return context;
};
