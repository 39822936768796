import React, {useEffect, useState} from 'react';
import {
  Card,
  CardContent,
  Typography,
  Chip,
  Grid,
  Container,
  Collapse,
  IconButton,
  Box,
} from '@mui/material';
import {PermissionDto} from '../../../api/dto/permission';
import {getUserMePermission} from '../../../api/user';
import Dashboard from '../../../components/Dashboard';
import {useTranslation} from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; // 展開圖標
import ExpandLessIcon from '@mui/icons-material/ExpandLess'; // 收起圖標
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const AccountPermission = () => {
  const {t} = useTranslation();
  const [permissions, setPermissions] = useState<PermissionDto[]>();
  const [expanded, setExpanded] = useState<Record<string, boolean>>({});
  const platformId = localStorage.getItem('platformId');

  useEffect(() => {
    getUserMePermission().then(d => {
      const permissionList = d.data.data[platformId ?? ''];
      setPermissions(permissionList);
    });
  }, [platformId]);

  // 定義每個 scope 對應的顏色，顏色值必須是 MUI 中的合法選項
  const scopeColors: Record<
    string,
    'primary' | 'secondary' | 'default' | 'error'
  > = {
    '*': 'error',
    users: 'secondary',
    plans: 'secondary',
    permissions: 'primary',
    roles: 'primary',
    platforms: 'primary',
  };

  const groupedPermissions = permissions?.reduce((acc, permission) => {
    const scope = permission.scope;
    if (!acc[scope]) {
      acc[scope] = [];
    }
    acc[scope].push(permission);
    return acc;
  }, {} as Record<string, PermissionDto[]>);

  const handleExpandClick = (scope: string) => {
    setExpanded(prevExpanded => ({
      ...prevExpanded,
      [scope]: !prevExpanded[scope],
    }));
  };

  const handleExpandAll = () => {
    const newExpanded = Object.keys(groupedPermissions || {}).reduce(
      (acc, scope) => {
        acc[scope] = true;
        return acc;
      },
      {} as Record<string, boolean>,
    );
    setExpanded(newExpanded);
  };

  // 全部收起
  const handleCollapseAll = () => {
    const newExpanded = Object.keys(groupedPermissions || {}).reduce(
      (acc, scope) => {
        acc[scope] = false;
        return acc;
      },
      {} as Record<string, boolean>,
    );
    setExpanded(newExpanded);
  };

  return (
    <Container
      id='pricing'
      sx={{
        pt: {xs: 4, sm: 12},
        pb: {xs: 8, sm: 16},
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: {xs: 3, sm: 6},
      }}
    >
      <Card variant='outlined' sx={{maxWidth: 500, margin: '20px auto'}}>
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant='h6' component='div' gutterBottom>
              {t('dashboardAccountPermission')}
            </Typography>

            <Box>
              <IconButton
                onClick={handleExpandAll}
                aria-label='expand all'
                sx={{padding: 0}}
              >
                <ExpandMoreIcon />
              </IconButton>
              <IconButton
                onClick={handleCollapseAll}
                aria-label='collapse all'
                sx={{padding: 0}}
              >
                <ExpandLessIcon />
              </IconButton>
            </Box>
          </Box>

          <Grid container spacing={1} sx={{marginTop: 2}}>
            {!groupedPermissions ||
            Object.keys(groupedPermissions).length === 0 ? (
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <ErrorOutlineIcon sx={{marginRight: 1}} />
                  <Typography variant='subtitle2' sx={{fontWeight: 'bold'}}>
                    {t('dashboardAccountPermissionNoPermission')}
                  </Typography>
                </Box>
              </Grid>
            ) : (
              Object.keys(groupedPermissions).map(scope => (
                <Grid item key={scope} xs={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography
                      variant='subtitle2'
                      sx={{fontWeight: 'bold', flexGrow: 1}}
                    >
                      {t(`scope.${scope}`) || scope}
                    </Typography>

                    <IconButton
                      onClick={() => handleExpandClick(scope)}
                      aria-expanded={expanded[scope]}
                      aria-label='show more'
                      sx={{padding: 0}}
                    >
                      {expanded[scope] ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </IconButton>
                  </Box>

                  <Collapse in={expanded[scope]} timeout='auto' unmountOnExit>
                    {groupedPermissions?.[scope]?.map((permission, index) => (
                      <Chip
                        key={index}
                        label={`${permission.scope} - ${permission.action}`}
                        color={scopeColors[scope] || 'default'}
                        variant='outlined'
                        sx={{fontSize: 14, marginRight: 1, marginBottom: 1}}
                      />
                    ))}
                  </Collapse>
                </Grid>
              ))
            )}
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};

export default function DashboardUserMe() {
  return <Dashboard element={<AccountPermission />} />;
}
