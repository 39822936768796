import {AxiosResponse} from 'axios';
import {
  GetUserMessageListDto,
  PatchUserMessageDto,
  PostUserMessageDto,
  UserMessageDto,
} from './dto/userMessage';
import callApi from './index';

export const createUserMessage = (
  data: PostUserMessageDto,
): Promise<AxiosResponse<{data: UserMessageDto}, any>> | undefined => {
  return callApi('post', `/user/aimessage`, data);
};

export const getUserMessageList = (
  data: GetUserMessageListDto,
): Promise<AxiosResponse<{items: UserMessageDto[]}, any>> | undefined => {
  const options = {orderBy: 'createdAt', sort: 'DESC', ...data};
  return callApi('get', `/user/aimessage/list`, options);
};

export const updateUserMessage = (id: string, data: PatchUserMessageDto) => {
  return callApi('patch', `/user/aimessage/${id}`, data);
};

export const deleteUserMessage = (id: string) => {
  return callApi('delete', `/user/aimessage/${id}`);
};
