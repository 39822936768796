import React, {createContext, useContext, useEffect, useState} from 'react';
import {PlatformCreditDto} from '../api/dto/platformCredit';
import {getPlatformCredit} from '../api/platformCredit';
import {HttpStatusCode} from 'axios';

interface PlatformCreditContextType {
  platformCredit?: PlatformCreditDto;
  setPlatformCredit: (platformCredit?: PlatformCreditDto) => void;
}

const PlatformCreditContext = createContext<
  PlatformCreditContextType | undefined
>(undefined);

export const PlatformCreditProvider = ({
  type,
  children,
}: {
  type: string;
  children: React.ReactNode;
}) => {
  const [platformCredit, setPlatformCredit] = useState<
    PlatformCreditDto | undefined
  >(() => {
    const cachedPlatformCredit = localStorage.getItem(`platformCredit:${type}`);
    return cachedPlatformCredit ? JSON.parse(cachedPlatformCredit) : undefined;
  });

  useEffect(() => {
    if (!platformCredit) {
      getPlatformCredit({type})?.then(d => {
        setPlatformCredit(d.data?.data);
        if (d.status === HttpStatusCode.Ok) {
          localStorage.setItem(
            `platformCredit:${type}`,
            JSON.stringify(d.data?.data),
          );
        }
      });
    }
  }, [platformCredit]);

  return (
    <PlatformCreditContext.Provider value={{platformCredit, setPlatformCredit}}>
      {children}
    </PlatformCreditContext.Provider>
  );
};

export const usePlatformCredit = () => {
  const context = useContext(PlatformCreditContext);
  if (!context) {
    throw new Error(
      'usePlatformCredit must be used within a PlatformCreditProvider',
    );
  }
  return context;
};
