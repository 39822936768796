import {AxiosResponse} from 'axios';
import callApi from './index';
import {GetWebhookListDto, WebhookDto} from './dto/webhook';

export const getWebhookList = (
  data: GetWebhookListDto,
): Promise<AxiosResponse<{items: WebhookDto[]}, any>> | undefined => {
  const options = {orderBy: 'createdAt', sort: 'DESC', ...data};
  return callApi('get', `/webhook/list`, options);
};
