import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import Dashboard from '../../components/Dashboard';
import React, {ReactElement, ReactNode, useMemo, useState} from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import TWithProviders, {
  MRT_ColumnDef_Add_Show,
  validateRequired,
} from '../../components/MaterialTable';
import {
  createPermission,
  deletePermission,
  getPermissionList,
} from '../../api/permission';
import {MRT_Row, MRT_TableInstance} from 'material-react-table';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  Alert,
  AlertColor,
  Chip,
  IconButton,
  Snackbar,
  SnackbarOrigin,
  Tooltip,
} from '@mui/material';
import {ALERT_HIDE_DURATION} from '../../constant';
import {CreatedAtColumn} from '../../components/Date/CreatedAtColumn';
import InfoIcon from '@mui/icons-material/Info';
import {usePlatformUsage} from '../../context/platformUsage';
dayjs.extend(utc);

function validate(data: any) {
  return {
    scope: !validateRequired(data.scope) ? 'Scope is Required' : '',
    action: !validateRequired(data.action) ? 'Action is Required' : '',
  };
}

interface State extends SnackbarOrigin {
  open: boolean;
  type: AlertColor;
  message: string;
}
function PermissionContent(): ReactElement {
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const {t} = useTranslation();
  const {platformUsage} = usePlatformUsage();

  const [state, setState] = React.useState<State>({
    open: false,
    type: 'success',
    message: '',
    vertical: 'bottom',
    horizontal: 'right',
  });
  const {open} = state;

  const handleClose = () => {
    setState({...state, open: false});
  };

  const handleRenderActions = (
    row: any,
    table: MRT_TableInstance<any>,
    openDeleteConfirmModal: (row: MRT_Row<any>) => void,
  ): ReactNode => {
    if (row?.original?.isDefault) {
      return (
        <Tooltip title={t('dashboardTablePermissionActionsTooltip')}>
          <span>
            <IconButton disabled>
              <InfoIcon color='disabled' />
            </IconButton>
          </span>
        </Tooltip>
      );
    }

    return (
      <Box sx={{display: 'flex', gap: '1rem'}}>
        {
          <Tooltip title='Delete'>
            <IconButton
              color='error'
              onClick={() => openDeleteConfirmModal(row)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        }
      </Box>
    );
  };

  const columns = useMemo<MRT_ColumnDef_Add_Show<any>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'Id',
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: 'scope',
        header: t('scopeHeader'),
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.scope,
          helperText: validationErrors?.scope,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              scope: undefined,
            }),
        },
      },
      {
        accessorKey: 'action',
        header: t('actionHeader'),
        editSelectOptions: ['Create', 'Edit', 'View', 'Delete', '*'],
        muiEditTextFieldProps: {
          select: true,
          defaultChecked: true,
          defaultValue: true,
          error: !!validationErrors?.action,
          helperText: validationErrors?.action,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              action: undefined,
            }),
        },
      },
      {
        accessorKey: 'isDefault',
        header: t('actionHeader'),
        show: false,
        enableEditing: false,
        visibleInShowHideMenu: false,
        muiEditTextFieldProps: {
          error: !!validationErrors?.isDefault,
          helperText: validationErrors?.isDefault,
          sx: {display: 'none'},
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              isDefault: undefined,
            }),
        },
      },
      {
        accessorKey: 'createdAt',
        header: t('createdAtHeader'),
        enableEditing: false,
        Cell: ({cell}) => <CreatedAtColumn cell={cell} />,
      },
    ],
    [t, validationErrors],
  );

  return (
    <Container
      id='pricing'
      sx={{
        pt: {xs: 4, sm: 12},
        pb: {xs: 8, sm: 16},
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: {xs: 3, sm: 6},
      }}
    >
      <Box
        sx={{
          width: {sm: '100%', md: '60%'},
          textAlign: {sm: 'left', md: 'center'},
          display: 'flex',
          flexDirection: 'column',
          alignItems: {sm: 'flex-start', md: 'center'},
          gap: 1.5,
          py: 2,
        }}
      >
        <Typography
          component='h2'
          variant='h4'
          color='text.primary'
          fontWeight='bold'
        >
          {t('dashboardMenuPermission')}
        </Typography>
        <Typography
          variant='body1'
          color='text.secondary'
          sx={{maxWidth: '80%', opacity: 0.9}}
        >
          {t('dashboardTablePermissionDescription')}
        </Typography>
      </Box>
      <Grid
        container
        spacing={3}
        alignItems='center'
        justifyContent='center'
      ></Grid>
      <Tooltip title={t('dashboardTableCreditTooltip')} arrow>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginBottom: '-1rem',
          }}
        >
          <Chip
            label={
              <Box sx={{display: 'flex', alignItems: 'center', gap: '4px'}}>
                <Typography variant='body2' color='primary'>
                  {platformUsage?.permissionCount}
                </Typography>
                /
                <Typography variant='body2' color='text.secondary'>
                  {platformUsage?.ruleObj.maxLimitPlatformPermissionCount}
                </Typography>
              </Box>
            }
            size='small'
            color='default'
            sx={{
              fontSize: '0.75rem',
              height: 24,
              paddingX: 1.2,
              backgroundColor: 'background.paper', // 讓它更融合背景
              border: '1px solid #ccc', // 添加邊框讓它更清楚
            }}
          />
        </Box>
      </Tooltip>
      <TWithProviders
        props={{
          createApi: createPermission,
          //   updateApi: updatePermission,
          getListApi: getPermissionList,
          deleteApi: deletePermission,
        }}
        columns={columns}
        validate={validate}
        setValidateErrors={setValidationErrors}
        renderActionsFunc={handleRenderActions}
      ></TWithProviders>
      <Snackbar
        anchorOrigin={{vertical: state.vertical, horizontal: state.horizontal}}
        open={open}
        autoHideDuration={ALERT_HIDE_DURATION}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={state.type}
          variant='filled'
          sx={{width: '100%'}}
        >
          {state.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default function DashboardPermission() {
  return <Dashboard element={<PermissionContent />}></Dashboard>;
}
