import {AxiosResponse} from 'axios';
import {
  GetUserListDto,
  PatchUserDto,
  PatchUserMeEmailVerifyDto,
  PostUserDto,
  PostUserForgetPasswordDto,
  PostUserResetPasswordCheckDto,
  PostUserResetPasswordDto,
  PutUserSetRoleDto,
  UserDto,
  UserPermissionDto,
} from './dto/user';
import callApi from './index';

export const getUserMe = (): Promise<AxiosResponse<{data: UserDto}, any>> => {
  return callApi('get', `/user/me`) as Promise<
    AxiosResponse<{data: UserDto}, any>
  >;
};

export const getUserMeAccount = () => {
  return callApi('get', `/user/me/account`);
};

export const getUserMePermission = (): Promise<
  AxiosResponse<{data: UserPermissionDto}, any>
> => {
  const options = {orderBy: 'createdAt', sort: 'DESC'};
  return callApi('get', `/user/me/permission/list`, options) as Promise<
    AxiosResponse<{data: UserPermissionDto}, any>
  >;
};

export const createUser = (
  data: PostUserDto,
): Promise<AxiosResponse<{data: UserDto}, any>> | undefined => {
  return callApi('post', `/user`, data);
};

export const postUserMeResendVerificationEmail = ():
  | Promise<AxiosResponse<{data: UserDto}, any>>
  | undefined => {
  return callApi('post', `/user/me/email/verify/resend`);
};

export const patchUserMeVerificationEmail = (
  data: PatchUserMeEmailVerifyDto,
): Promise<AxiosResponse<{data: UserDto}, any>> | undefined => {
  return callApi('patch', `/user/me/email/verify`, data);
};

export const postUserForgetPassword = (
  data: PostUserForgetPasswordDto,
): Promise<AxiosResponse<{data: UserDto}, any>> | undefined => {
  return callApi('post', `/user/password/forget`, data);
};

export const postUserResetPasswordCheck = (
  data: PostUserResetPasswordCheckDto,
): Promise<AxiosResponse<{data: UserDto}, any>> | undefined => {
  return callApi('post', `/user/password/reset/check`, data);
};

export const patchUserResetPassword = (
  data: PostUserResetPasswordDto,
): Promise<AxiosResponse<{data: UserDto}, any>> | undefined => {
  return callApi('patch', `/user/password/reset`, data);
};

export const getUserList = (data: GetUserListDto) => {
  const options = {orderBy: 'createdAt', sort: 'DESC', ...data};
  return callApi('get', `/user/list`, options);
};

export const getUserById = (
  id: string,
): Promise<AxiosResponse<{data: UserDto}, any>> | undefined => {
  return callApi('get', `/user/${id}`);
};

export const updateUser = (id: string, data: PatchUserDto) => {
  return callApi('patch', `/user/${id}`, data);
};

export const updateUserMe = (
  data: any,
): Promise<AxiosResponse<{data: number}, any>> | undefined => {
  return callApi('patch', `/user/me`, data);
};

export const updateUserSetRole = (
  id: string,
  data: PutUserSetRoleDto,
): Promise<AxiosResponse<{data: number}, any>> | undefined => {
  return callApi('put', `/user/${id}/role/set`, data);
};

export const deleteUser = (id: string) => {
  return callApi('delete', `/user/${id}`);
};
