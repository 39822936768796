import React, {createContext, useContext, useEffect, useState} from 'react';
import {HttpStatusCode} from 'axios';
import {PlatformUsageDto} from '../api/dto/platform';
import {getPlatformUsage} from '../api/platform';

interface PlatformUsageContextType {
  platformUsage?: PlatformUsageDto;
  setPlatformUsage: (platformUsage?: PlatformUsageDto) => void;
  updatePlatformUsage: (platformUsage?: PlatformUsageDto) => void;
}

const PlatformUsageContext = createContext<
  PlatformUsageContextType | undefined
>(undefined);

export const PlatformUsageProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [platformUsage, setPlatformUsage] = useState<
    PlatformUsageDto | undefined
  >(() => {
    const cachedPlatformUsage = localStorage.getItem('platformUsage');
    return cachedPlatformUsage ? JSON.parse(cachedPlatformUsage) : undefined;
  });

  const updatePlatformUsage = (
    newPlatformUsage: PlatformUsageDto | undefined,
  ) => {
    setPlatformUsage(newPlatformUsage);

    if (newPlatformUsage) {
      localStorage.setItem('platformUsage', JSON.stringify(newPlatformUsage));
    } else {
      localStorage.removeItem('platformUsage');
    }
  };

  useEffect(() => {
    if (!platformUsage) {
      getPlatformUsage()?.then(d => {
        setPlatformUsage(d.data?.data);
        if (d.status === HttpStatusCode.Ok) {
          localStorage.setItem('platformUsage', JSON.stringify(d.data?.data));
        }
      });
    }
  }, [platformUsage]);

  return (
    <PlatformUsageContext.Provider
      value={{platformUsage, setPlatformUsage, updatePlatformUsage}}
    >
      {children}
    </PlatformUsageContext.Provider>
  );
};

export const usePlatformUsage = () => {
  const context = useContext(PlatformUsageContext);
  if (!context) {
    throw new Error(
      'usePlatformUsage must be used within a PlatformUsageProvider',
    );
  }
  return context;
};
